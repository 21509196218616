import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { GlobalTranslationsContext, BeaconContext } from '../pages/Contexts';
import { getAppClassTheme } from '../utils/customizeStyle';

import ProgressBar, {MiniProgressBar} from './ProgressBar';

const PulseCountdown = ({ colorTheme = 'default', pulseView = false, isMini = false }) => {

  const [ t ] = useContext(GlobalTranslationsContext);
  const { pulse, loading } = useContext(BeaconContext);
  const [ percent, setPercent ] = useState(0);

  /**
   * @description Updates the remaining seconds until the next pulse.
   */
  const pulseTimer = useCallback(() => {
    const now = new Date();
    let currSec = (now.getSeconds() + now.getMilliseconds()/1000) % 60;
    setPercent((1 - currSec / 60)*100);
  }, [])

  /**
   * @description This hook starts and stops the timer when the component is
   * mounted or unmounted respectively.
   */
  useEffect( () => {    
    const intervalId = setInterval(pulseTimer, 100);
		return () => { 
      clearInterval(intervalId);
		}
	}, [pulseTimer])

  const { appContainer, pulseContainer } = getAppClassTheme(colorTheme);

  if (isMini) {
    return (
      <div className='mini-progress-container'>
        <div>
          <MiniProgressBar loading={loading} percentage={percent} />
        </div>
        <div className='mini-time-left'>
          <h2>
            { t('home.prng.pulse') + ' '} 
            {
              loading ? t('home.prng.loading') : <a href={pulse.uri} target='_blank' rel='noreferrer'>#{pulse.pulseIndex}</a>
            }
          </h2>
        </div>
      </div>
    )
  }

  return (
    <div className={pulseView ? '' : appContainer }>
      <div className={`is-flex is-justify-content-space-around is-flex-wrap-wrap ${pulseContainer} ${pulseView? 'viewer-countdown' : ''}`}>
        <ProgressBar loading={loading} percentage={percent} />
        <div className='seed-description'>
          <h2>
            {
              loading ? 
                t('home.prng.loading') : 
                <>
                  { t('home.prng.pulse')} 
                  <Link to={`/randomness-beacon/advanced-viewer?chainId=${pulse.chainIndex}&pulseId=${pulse.pulseIndex}`}>
                    {` #${pulse.pulseIndex}`}
                  </Link>
                </>
            }
          </h2>
          {
            pulseView ||
            <>
              <hr />
              <p>{ t('home.prng.time-until') + ' '}
                <Link to='/randomness-beacon'>{ t('home.prng.about') }</Link>
              </p>
            </>
          } 
        </div>
      </div>
    </div>
  )
}

export default PulseCountdown