import React from 'react';
import { useTranslation } from 'react-i18next';

const ExternalItem = ({source, color, url}) => {
  const [t] = useTranslation('beacon');
  const image = require(`../../assets/images/externals/${source}.png`);

  return (
    <div className='external-container' style={{'--color': color}} onClick={() => window.open(url)}>
      <div className='external-icon'>
        <img src={image} alt={source} />
      </div>
      <h3 className='external-title'>{ t(`sections.external.sources.${source}.title`) }</h3>
      <p className='external-description'>{ t(`sections.external.sources.${source}.description`) }</p>
    </div>
  )
}

export default ExternalItem