import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { LocalTranslationsContext } from '../Contexts';

import EntropyLeague from '../../assets/images/about/eleague.png';
import Nist from '../../assets/images/about/nist.png';
import Inmetro from '../../assets/images/about/inmetro.png';

const Description = () => {

  const [ t ] = useContext(LocalTranslationsContext);

  return (
    <>
      <div>
        <h2>Random UChile</h2>
        <div className='about-index'>
          <h3>
            <a href='#goal'>
              1. {t('sections.goal.title')}
            </a>
          </h3>
          <h3>
            <a href='#beacon'>
              2. {t('sections.beacon.title')}
            </a>
          </h3>
          <h3>
            <a href='#transparency'>
              3. {t('sections.transparency.title')}
            </a>
          </h3>
          <h3>
            <a href='#apps'>
              4. {t('sections.apps.title')}
            </a>
          </h3>
          <h3>
            <a href='#verifiability'>
              5. {t('sections.verifiability.title')}
            </a>
          </h3>
          <h3>
            <a href='#similar'>
              6. {t('sections.similar.title')}
            </a>
          </h3>
        </div>
      </div>
      <hr />
			
      <h2 id='goal'>
        1. {t('sections.goal.title')}
      </h2>
      <div className='about-section'>
        <p className='has-text-justified'>{t('sections.goal.randomness')}</p>
        <p className='has-text-justified'>{t('sections.goal.project')}</p>
      </div>

      <h2 id='beacon'>
        2. {t('sections.beacon.title')}
      </h2>
      <div className='about-section'>
        <p className='has-text-justified'>
          {t('sections.beacon.description')}:
          <NavLink to='/randomness-beacon'>
            {' ' + t('sections.beacon.link')}.
          </NavLink>
        </p>
      </div>

      <h2 id='transparency'>
        3. {t('sections.transparency.title')}
      </h2>
      <div className='about-section'>
        <p className='has-text-justified'>{t('sections.transparency.par0')}</p>
        <p className='has-text-justified'>{t('sections.transparency.par1')}</p>
        <p className='has-text-justified'>
          {t('sections.transparency.par2')}
          <NavLink to='/projects'>
            {' ' + t('sections.transparency.link')}.
          </NavLink>
        </p>
      </div>

      <h2 id='apps'>
        4. {t('sections.apps.title')}
      </h2>
      <div className='about-section'>
        <p className='has-text-justified'>
          {t('sections.apps.par0')}
          <NavLink to='/apps'>
            {' ' + t('sections.apps.link')}.
          </NavLink>
        </p>
      </div>

      <h2 id='verifiability'>
        5. {t('sections.verifiability.title')}
      </h2>
      <div className='about-section'>
        <p className='has-text-justified'>
          {t('sections.verifiability.par0')}
        </p>
      </div>

      <h2 id='similar'>
        6. {t('sections.similar.title')}
      </h2>
      <div className='about-section'>
        <h3>6.1 {t('sections.similar.services.nist.title')}</h3>
        <h4><a target='_blank' rel='noreferrer' href='https://beacon.nist.gov/home'>https://beacon.nist.gov/</a></h4>
        <div className='about-section columns'>
          <div className='column is-one-fifth  has-text-centered'>
            <img src={Nist} className='similar-service-logo'  alt='NIST Logo'/>
          </div>
          <div className='project-description column'>
            <p className='has-text-justified'>{t('sections.similar.services.nist.description')}</p>
          </div>
        </div>

        <h3>6.2 {t('sections.similar.services.inmetro.title')}</h3>
        <h4><a target='_blank' rel='noreferrer' href='https://beacon.inmetro.gov.br/'>https://beacon.inmetro.gov.br/</a></h4>
        <div className='about-section columns'>
          <div className='column is-one-fifth  has-text-centered'>
            <img src={Inmetro} className='similar-service-logo'  alt='Brazil Inmetro Logo'/>
          </div>
          <div className='project-description column'>
            <p className='has-text-justified'>{t('sections.similar.services.inmetro.description')}</p>
          </div>
        </div>

        <h3>6.3 {t('sections.similar.services.eleague.title')}</h3>
        <h4><a target='_blank' rel='noreferrer' href='https://www.cloudflare.com/leagueofentropy/'>https://www.cloudflare.com/leagueofentropy/</a></h4>
        <div className='about-section columns'>
          <div className='column is-one-fifth has-text-centered'>
            <img src={EntropyLeague} className='similar-service-logo' alt='Entropy League Logo'/>
          </div>
          <div className='column'>
            <p className='has-text-justified'>
              {t('sections.similar.services.eleague.description')}
              <NavLink to='/projects/league-of-entropy'>
              {' ' + t('sections.similar.services.eleague.link')}.
              </NavLink>
            </p>
          </div>
        </div>
      </div>
		</>
  )
}

export default Description