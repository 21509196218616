import React from 'react';

const OrganizationsUsing = ({t}) => {
  return (
    <div>
      <h2>{ t('organizations.title') }</h2>
      <p>
        { t('organizations.description.paragraph-1') + ' '}
        <a href='mailto:random@uchile.cl' target='_blank' rel='noreferrer'>
          { t('organizations.description.mail') }
        </a>.
      </p>
      <p>
        { t('organizations.description.paragraph-2') }
      </p>
    </div>
  )
}

export default OrganizationsUsing