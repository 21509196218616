import About from "../pages/About";
import Apps from "../pages/Apps";
import BeaconScreen from "../pages/Beacon";
import News from "../pages/News";
import PeopleScreen from "../pages/People";
import Projects from "../pages/Projects";
import UsingScreen from "../pages/Using";

export const menuItems = [
    {
        text: 'about',
        url: '/about',
        component: <About />
    },
    {
        text: 'how-it-works',
        url: '/randomness-beacon',
        component: <BeaconScreen />
    },
    {
        text: 'apps',
        url: '/apps',
        component: <Apps />
    },
    {
        text: 'using',
        url: '/using',
        component: <UsingScreen />
    },
    {
        text: 'projects',
        url: '/projects',
        component: <Projects />
    },
    {
        text: 'news',
        url: '/news',
        component: <News />
    },
    {
        text: 'people',
        url: '/people',
        component: <PeopleScreen />
    },
]