import React from 'react';
import { Link } from 'react-router-dom';

import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProjectsBreadcrumb = ({t}) => {

  return (
    <Link to="/projects">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faListCheck} />  
      </span>
      <span>{t('sites.projects')}</span>
    </Link>
  )
}

export default ProjectsBreadcrumb