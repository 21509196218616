import React from 'react';

const DevelopersUsing = ({t}) => {
  return (
    <div>
      <h2>{ t('developers.title') }</h2>
      <p>
        { t('developers.description.introduction') }
      </p>
      <h3>1. { t('developers.endpoints.title') }</h3>
      <p>
        { t('developers.endpoints.description.part-1') }
        <code>HTTP GET</code>{ t('developers.endpoints.description.part-2') }<code>https://random.uchile.cl/beacon/2.1-beta/pulse</code>. { t('developers.endpoints.description.part-3') }
      </p>
      <ul>
        <li>
          <b>{ t('developers.endpoints.no-params.title') }</b>: { t('developers.endpoints.no-params.description') }
        </li>
        <li>
          <b>{ t('developers.endpoints.by-id.title') }</b>: { t('developers.endpoints.by-id.description-1') } (<code>chainId</code>) { t('developers.endpoints.by-id.description-2') } (<code>pulseId</code>). { t('developers.endpoints.by-id.description-3') }, <code>https://random.uchile.cl/beacon/2.1-beta/pulse?chainId=1&pulseId=100</code> { t('developers.endpoints.by-id.description-4') }
        </li>
        <li>
          <b>{ t('developers.endpoints.by-timestamp.title') }</b>: { t('developers.endpoints.by-timestamp.description-1') } <code>https://random.uchile.cl/beacon/2.1-beta/pulse?timeGE=2024-08-01T00:00:00Z</code> { t('developers.endpoints.by-timestamp.description-2') }
        </li>
        <li>
          <b>{ t('developers.endpoints.by-randout.title') }</b>: { t('developers.endpoints.by-randout.description-1') } <code>https://random.uchile.cl/beacon/2.1-beta/pulse?outputValue=1234567890abcdef</code> { t('developers.endpoints.by-randout.description-2') }
        </li>
      </ul>
      <p>
      { t('developers.endpoints.description.part-4') }<a href='https://random.uchile.cl/beacon/2.1-beta/docs/index.html' target='_blank' rel='noreferrer'>{ t('developers.endpoints.description.docs') }</a>.
      </p>
      
      <h3>2. { t('developers.getting-data.title') }</h3>
      <p>
      { t('developers.getting-data.description') }<code>pulse.outputValue</code>.
      </p>
      <br/>
      <b>{ t('developers.getting-data.api-querying') } (Javascript)</b>
      <pre>
        <code lang='javascript'>
          fetch('https://random.uchile.cl/beacon/2.1-beta/pulse')<br/>
          &nbsp;&nbsp;.then(response ={'>'} response.json())<br/>
          &nbsp;&nbsp;.then(data ={'>'} data.pulse.outputValue)<br/>
          &nbsp;&nbsp;.then(outputValue ={'>'} doSomething(outputValue));
        </code>
      </pre>
      <br/>
      <b>{ t('developers.getting-data.api-querying') } (Python)</b>
      <pre>
        <code lang='python'>
          import requests<br/>
          <br/>
          response = requests.get('https://random.uchile.cl/beacon/2.1-beta/pulse')<br/>
          data = response.json()<br/>
          outputValue = data['pulse']['outputValue']<br/>
          doSomething(outputValue)
        </code>
      </pre>

      <h3>3. { t('developers.using-randout.title') }</h3>
      <p>
      { t('developers.using-randout.description-1') } <code>outputValue</code> { t('developers.using-randout.description-2') }
      </p>

      <h3>4. { t('developers.verification-data.title') }</h3>
      <p>
        { t('developers.verification-data.paragraph-1') }
      </p>
      <ul>
        <li>
          { t('developers.verification-data.item-1') }
        </li>
        <li>
          { t('developers.verification-data.item-2') }
        </li>
        <li>
          { t('developers.verification-data.item-3') }
        </li>
        <li>
          { t('developers.verification-data.item-4') }
        </li>
      </ul>
      <p>
        { t('developers.verification-data.paragraph-2') }
      </p>

      <h3>5. { t('developers.external-verification.title') }</h3>
      <p>
        { t('developers.external-verification.paragraph-1') }
      </p>
      <ul>
        <li>
          <b>{ t('developers.external-verification.item-1.title') }</b>: { t('developers.external-verification.item-1.description') }
        </li>
        <li>
          <b>{ t('developers.external-verification.item-2.title') }</b>: { t('developers.external-verification.item-2.description') }
        </li>
        <li>
          <b>{ t('developers.external-verification.item-3.title') }</b>: { t('developers.external-verification.item-3.description') }
        </li>
        <li>
          <b>{ t('developers.external-verification.item-4.title') }</b>: { t('developers.external-verification.item-4.description') }
        </li>
      </ul>

      <h3>6. { t('developers.example-lottery.title') }</h3>
      <p>
        { t('developers.example-lottery.description.part-1') } <a href='https://github.com/clcert/beacon-random-comments/blob/master/documentation/lotterytutorial.md' target='_blank' rel='noreferrer'>{ t('developers.example-lottery.description.part-2') }</a> { t('developers.example-lottery.description.part-3') }
      </p>
    </div>
  )
}

export default DevelopersUsing