import React, { useEffect } from 'react';

const TextArea = ({label, placeholder, content, setContent, error, errorMsg}) => {

  useEffect(() => {
    const textArea = document.querySelector('.textarea');
    const textAreaError = document.querySelector('.textarea-error');
    if (error) {
      textArea.classList.add('is-danger');
      textAreaError.classList.remove('is-hidden');
    } else {
      textArea.classList.remove('is-danger');
      textAreaError.classList.add('is-hidden');
    }
  }, [error]);

  return (
    <div className='has-text-centered'>
      <label>{ label }</label>
      <div className='control'>
        <textarea
          className='textarea mt-3'
          placeholder={ placeholder }
          onChange={(e) => setContent(e.target.value)} 
          value={content}
          maxLength='20000'/>
        <p className='characters-limit'>{content.length } / 20000</p>
        <p className='textarea-error help is-danger is-hidden'>
          { errorMsg }
        </p>
      </div>
    </div>
  )
}

export default TextArea