import { BINGOAPP, NUMBERSAPP, SHUFFLEAPP, RANDPICKERAPP } from "./utils";

export const validatePRNGInput = ( nums, min, max ) => {
	const range = max - min;
	return nums > 0 && range >= nums;
}

export const validateListShuffleInput = ( rawInput ) => {
	return rawInput.length > 0;
}

const validateBingoValues = ( numbers ) => {
	for(const n of numbers.split(' - ')) {
		if(parseInt(n) < 1 || parseInt(n) > 75) return false;
	}
	return true
}

export const validateValues = (values) => {
  switch (values.app) {
		case NUMBERSAPP:
			return validatePRNGInput(values.nums, values.min, values.max);
		
		// eslint-disable-next-line no-fallthrough
		case RANDPICKERAPP: 
		case SHUFFLEAPP:
			return validateListShuffleInput(values.rawInput);

		case BINGOAPP:
			return validateBingoValues(values.output);

		default:
			return false;
	}
}

export const validateVerificationCode = (code) => {
  return code.split('-').length === 3;
}

export const validateVerificationCodes = (codes) => {
	for (const code of codes.split(',')) {
  	if (!validateVerificationCode(code)) return false;
	}
	return true;
}