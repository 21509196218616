import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
import ExternalItem from './ExternalItem';
import { externalSources } from '../../data/externalSources';
import BasicPageWrapper from '../../components/BasicPageWrapper';

const BeaconScreen = () => {

  const [ t ] = useTranslation('beacon');

  return (
    <BasicPageWrapper transProv={t} customClass='beacon-page'>
      <div className='box-content increased-padding beacon-description-container'>
        <h1>{ t('main-title') }</h1>
        <div className='tabs is-centered'>
          <ul>
            <li><a href='#algorithm-section'>{ t('sections.algorithm.title') }</a></li>
            <li><a href='#external-section'>{ t('sections.external.title') }</a></li>
            <li><a href='#tools-section'>{ t('sections.tools.title') }</a></li>
          </ul>
        </div>
        <div className='beacon-description'>
          <p className='has-text-justified'>{ t('description-1') }</p>
          <br/>
          <p className='has-text-justified'>{ t('description-2') }</p>

          <div className='beacon-section'>
            <h2 id='algorithm-section' className='section-title'>{ t('sections.algorithm.full-title') }</h2>
            <p className='has-text-justified'>{ t('sections.algorithm.introduction') }</p>
            <ul>
              <li className='has-text-justified'>
                <b>{ t('sections.algorithm.phases.collector.title') }:</b> { t('sections.algorithm.phases.collector.content') }
                <ol className='sub-items'>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.collector.reqs-1') }</li>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.collector.reqs-2') }</li>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.collector.reqs-3') }</li>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.collector.reqs-4') }</li>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.collector.reqs-5') }</li>
                </ol>
              </li>
              <li>
                <p className='has-text-justified'><b>{ t('sections.algorithm.phases.local.title') }:</b> { t('sections.algorithm.phases.local.description-1') }</p>
                <p className='has-text-justified'>{ t('sections.algorithm.phases.local.description-2') }</p>
              </li>
              <li>
                <p className='has-text-justified'><b>{ t('sections.algorithm.phases.publish.title') }</b> { t('sections.algorithm.phases.publish.introduction-phrase') }</p>
                <ol className='sub-items'>
                  <li className='has-text-justified'>
                    { t('sections.algorithm.phases.publish.step-1') }
                    <a href='#vdf-appendix'>Verifiable Delay Function<sup>1</sup></a>).
                    { " " + t('sections.algorithm.phases.publish.step-1-1') }.
                    { " " + t('sections.algorithm.phases.publish.step-1-2') } 
                  
                  </li>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.publish.step-2') }</li>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.publish.step-3') }</li>
                  <li className='has-text-justified'>{ t('sections.algorithm.phases.publish.step-4') }</li>
                </ol>
              </li>
            </ul>
          </div>
          
          <div className='beacon-section'>
            <h2 id='external-section' className='section-title'>{ t('sections.external.full-title') }</h2>
            <p>{ t('sections.external.introduction') }</p>
            <div className='is-flex is-justify-content-space-around is-flex-wrap-wrap'>
              {
                externalSources.map((src) => (
                  <ExternalItem key={src.title} source={src.title} color={src.color} url={src.url} />
                ))
              }
            </div>
          </div>

          <div className='beacon-section'>
            <h2 id='tools-section' className='section-title'>{ t('sections.tools.full-title') }</h2>
            <ol className='beacon-tools'>
              <li className='has-text-justified'>
                <Link to='/randomness-beacon/viewer'>
                  <FontAwesomeIcon className='spaced-fa-icon' icon={faEye} />
                  { t('sections.tools.viewer.simple.title') }
                </Link>
                <p>{ t('sections.tools.viewer.simple.description') }</p>
              </li>
              <li className='has-text-justified'>
                <Link to='/randomness-beacon/advanced-viewer'>
                  <FontAwesomeIcon className='spaced-fa-icon' icon={faMagnifyingGlass} />
                  { t('sections.tools.viewer.advanced.title') }
                </Link>
                <p>{ t('sections.tools.viewer.advanced.description') }</p>
              </li>
              {/* <li className='has-text-justified'>
                <Link to='/development'>
                  <FontAwesomeIcon className='spaced-fa-icon' icon={faArrowUpRightFromSquare} />
                  { t('sections.tools.api.title') }
                </Link>
                <p>{ t('sections.tools.api.description') }</p>
              </li> */}
            </ol>
          </div>

        </div>
        <div className='notes-section'>
          <sup>1</sup><b id='vdf-appendix'>Verifiable Delay Functions</b>:
          { " " + t('sections.appendix.vdf.proposed-by') } <i>Dan Boneh</i>,
          { " " + t('sections.appendix.vdf.consist-in') } <i>Benjamin Wesolowski </i>
          (
            {t('sections.appendix.vdf.described-in') + " " }
            <a href='https://ir.cwi.nl/pub/30021/Wesolowski2020_Article_EfficientVerifiableDelayFuncti.pdf'>
              { t('sections.appendix.vdf.this-paper') }
            </a>
          ),
          { " " + t('sections.appendix.vdf.using-classgroup') + " " }
          <i>{ t('sections.appendix.vdf.iqf') }</i>.
          { " " + t('sections.appendix.vdf.implementation') }
          <a href='https://github.com/Chia-Network/chiavdf'>{ " " + t('sections.appendix.vdf.repo') }</a>.
        </div>
      </div>
    </BasicPageWrapper>
  )
}

export default BeaconScreen