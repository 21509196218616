import React from 'react';
import PropTypes from 'prop-types';
import { LocalTranslationsContext } from '../pages/Contexts';

export default function BasicPageWrapper({t, children, customClass=''}) {
  return (
    <div className={`body-center container ${customClass}`}>
      <LocalTranslationsContext.Provider value={[ t ]} >
        {children}
      </LocalTranslationsContext.Provider>
    </div>
  )
}

BasicPageWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string
}