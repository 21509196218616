import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalTranslationsContext, BeaconContext } from '../../Contexts';
import { qrCode } from '../../../utils/verification';
import { shuffleList } from '../../../utils/randomness';
import { buildUrl, showModal, SHUFFLEAPP, RANDPICKERAPP, FILE, KEYBOARD, INT_REGEXP } from '../../../utils/utils';

import loadingGif from '../../../assets/images/loading.gif';
import SharingModal from '../SharingModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20, faFileUpload, faKeyboard, faPlusCircle, faQrcode } from '@fortawesome/free-solid-svg-icons';

import TextArea from '../../../components/TextArea';
import KeyboardOutput from './ShuffleKeyboardOutput';
import FileInput from '../../../components/FileInput';
import FileOutput from './ShuffleFileOutput';
import FileVerificationModal from '../FileVerificationModal';
import { getNewFile } from '../../../utils/fileHandlers';
import PickerOutput from './PickerOutput';
import NumInput from '../../../components/NumInput';

const ShuffleApp = ({randomPicker = false}) => {

  const { chacha, pulse } = useContext(BeaconContext);
  const [ tg ] = useContext(GlobalTranslationsContext);
  const [ t ] = useTranslation('apps');

  const [ loading, changeLoading ] = useState(false);
  const setLoading = useCallback(() => changeLoading(true), [ changeLoading ]);
  const unsetLoading = useCallback(() => changeLoading(false), [ changeLoading ]);

  const appName = randomPicker ? RANDPICKERAPP : SHUFFLEAPP;

  const [ inputStyle, setInputStyle ] = useState(KEYBOARD);

  const extendPicked = () => {
    setValues((values) => (
      { ...values, chosen: values.chosen + 1 }
    ));
    setChosen(values.chosen + 1);
    createVerificationUrl(values.code, values.outputList, values.outputFile, values.chosen + 1);
  }

  // Error Handling
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ contentError, setContentError ] = useState(false);
  const [ quatityError, setQuantityError ] = useState(false);
  const resetErrorMsg = useCallback(() => setErrorMsg(''), [ setErrorMsg ]);

  const [ values, setValues ] = useState({
    rawInput: '',
    outputList: '',
    fileInput: undefined,
    outputFile: undefined,
    code: '',
    chosen: 1,
  });
  const [ chosen, setChosen ] = useState(1);
  const cleanOutput = useCallback(() => setValues({ 
    ...values,
    outputList: '',
    outputFile: undefined 
  }), [ values, setValues ]);
  const cleanContent = useCallback(() => setValues({
    ...values,
    rawInput: '',
    fileInput: undefined,
    outputList: '',
    outputFile: undefined
  }), [ values, setValues ]);

  const [ url, setUrl ] = useState('');
  const [ ctr, setCtr ] = useState(1);

  // Handle the input change on submit
  const sanityCheck = useCallback(() => {
    resetErrorMsg();
    cleanOutput();

    let hasErrors = true;

    if (values.rawInput.trim().length === 0) {
      setErrorMsg(t('apps.shuffle.no-rows-error'));
      setContentError(true);
    } else if (values.rawInput.trim().length > 20000) {
      setErrorMsg(t('apps.shuffle.too-many-characters-error'));
      alert(t('apps.shuffle.too-many-characters-error-desc'))
      setContentError(true);
    } else if (!INT_REGEXP.test(chosen) || chosen < 1) {
      setErrorMsg(t('apps.picker.invalid-quantity-error'));
      setQuantityError(true);
    } else if (chosen > values.rawInput.trim().split('\n').length) {
      setErrorMsg(t('apps.picker.too-many-quantity-error'));
      setQuantityError(true);
    } else {
      hasErrors = false;
    }

    if (hasErrors) {
      unsetLoading();
    }
    return !hasErrors;
  }, [ values, resetErrorMsg, cleanOutput, t, setContentError, unsetLoading, chosen, setQuantityError ]);

  // Create the verification url
  const createVerificationUrl = useCallback((code, out, outFile, chosen) => {
    switch (inputStyle) {
      case KEYBOARD:
        setUrl( 
          buildUrl(appName, {...values, 
            code: code,
            rawInput: values.rawInput.replaceAll('\n','%0A'),
            output: out.join('%0A'),
            chosen: chosen,
            inStyle: KEYBOARD
          })
        );
        break;
      case FILE:
        const rawOutput = out.join('\n');
        setUrl( 
          buildUrl(appName, {...values, 
            code: code,
            rawInput: values.rawInput.replaceAll('\t','%09').replaceAll('\n','%0A'),
            output: rawOutput.replaceAll('\t','%09').replaceAll('\n','%0A'),
            inStyle: FILE,
            chosen: chosen,
            inFilename: values.fileInput.name,
            outFilename: outFile.name
          })
        );
        break;
      default:
        console.error('Invalid input style');
        break;
    }
  }, [values, inputStyle, appName]);


  /**
   * @description Handles the submit of a form and shuffles a list
   * @param e the Event 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const genButton = document.querySelector('#generator-button');
    const genButtonText = document.querySelector('#generate-button-text');
    genButton.disabled = true;
    setLoading();
    setTimeout(() => {
      if (sanityCheck()) {
        setContentError(false);
        setQuantityError(false);
        resetErrorMsg();
        setCtr((ctr) => ctr + 1);
        const verificationCode = `${pulse.chainIndex}-${pulse.pulseIndex}-${ctr}`;
        const rawList = values.rawInput.split('\n').map((row) => row.trim());
        const result = shuffleList(rawList, chacha);
        const outputFile = inputStyle === FILE ? getNewFile(result, values.fileInput.name, values.fileInput.type, verificationCode): undefined;
        genButtonText.innerHTML = t(`apps.${appName}.regenerate`).toLocaleUpperCase();
        switch (appName) {
          case RANDPICKERAPP:
            setValues({...values, 
              outputList: result,
              outputFile: outputFile,
              code: verificationCode,
              chosen: parseInt(chosen)
            });
            break;
          case SHUFFLEAPP:
            setValues({...values, 
              outputList: result,
              outputFile: outputFile,
              code: verificationCode,
            });
            break;
          default:
            console.error('Invalid app name');
            break;
        }
        unsetLoading();
        createVerificationUrl(verificationCode, result, outputFile, chosen);
      }
      genButton.disabled = false;
    }, 500);
  }

  const reseedAndReset = useCallback(() => {
    chacha.reseed(pulse.outputValue);
    setCtr(1);
  }, [chacha, pulse]);

  useEffect(() => {
    reseedAndReset(); // reseed whenever the input changes    
  }, [values.rawInput, values.fileInput, reseedAndReset]);

  // Reload QR code when url changes
  // If the url is too long, the QR code is not shown
  useEffect(() => {
    if (values.outputList) {
      const size = url.length * 0.8 > 250 ? url.length * 0.8 : 250;
      url.length <= 400 ?
        qrCode.update({ 
          data: url,
          width: size,
          height: size
        }) 
        : 
        qrCode.update({
          data: ''
        });
    }
  }, [url, values.outputList])

  const changeInputMode = (e, mode, toBeActivated) => {
    e.preventDefault();
    setInputStyle(mode);
    resetErrorMsg();
    setContentError(false);
    cleanContent();
    const inputTabs = document.querySelector('.input-shuffle').childNodes;
    inputTabs[1 - toBeActivated].classList.remove('is-active');
    inputTabs[toBeActivated].classList.add('is-active');
  }

  return (
    <div className='app-container-light'>
      <h1>{ t(`apps.${appName}.title`) }</h1>

      {/* Input Selector */}
      <div className='tabs is-centered'>
        <ul className='input-shuffle'>
          <li className='is-active'>
            <a href='/' onClick={(e) => changeInputMode(e, KEYBOARD, 0)}>
              <FontAwesomeIcon icon={ faKeyboard } />
              <p>{ t('apps.shuffle.write-input') }</p>
            </a>
          </li>
          <li>
            <a href='/' onClick={(e) => changeInputMode(e, FILE, 1)} >
              <FontAwesomeIcon icon={ faFileUpload }/>
              <p>{ t('apps.shuffle.file-upload') }</p>
            </a>
          </li>
        </ul>
      </div>

      { // Switch between keyboard input and file input
        {
          [KEYBOARD]: (
            <TextArea
              placeholder={ t('apps.shuffle.input-placeholder') }
              label={ t('apps.shuffle.keyboard-input-instructions') }
              content={ values.rawInput }
              setContent={ (l) => setValues({...values, rawInput: l}) }
              error={ contentError }
              errorMsg={ errorMsg }
            />
          ),
          [FILE]: (
            <FileInput
              labels={{
                'header': t('apps.shuffle.file-input-header'),
                'fileUpload': t('apps.shuffle.file-instructions'),
                'admittedFormats': t('apps.shuffle.admitted-formats'),
                'noFile': t('apps.shuffle.no-file'),
              }}
              file={ values.fileInput }
              setFileData={ (file, text) => (
                setValues({...values,
                  fileInput: file,
                  rawInput: text,
                  outputList: '',
                  outputFile: undefined
                })
              )}
              error={ contentError }
              errorMsg={ errorMsg }
            />
          )
        }[inputStyle]
      }

      {
        appName === RANDPICKERAPP && 
          <NumInput
            label={ t('apps.picker.choose-quantity') }
            number={ chosen }
            setNumber={ setChosen }
            error={ quatityError }
            errorMsg={ errorMsg }
          />
      }

      {
        loading ?
          <div className='shuffled-container waiting-shuffle' id='generator-result'>
            <img src={loadingGif} className='loading-icon' alt='...'/>
          </div> :
        contentError ? '' :
        inputStyle === KEYBOARD && appName === SHUFFLEAPP && values.outputList ?
          <KeyboardOutput
            t={ t }
            tg={ tg }
            values={ values }
          /> :
        inputStyle === FILE && appName === SHUFFLEAPP && values.outputList && values.fileInput ?
          <FileOutput
            t={ t }
            tg={ tg }
            values={ values }
          /> : 
        appName === RANDPICKERAPP && values.outputList ?
          <PickerOutput
            t={ t }
            tg={ tg }
            values={ values }
            choose={ values.chosen }
          /> : ''
      }

      <div className='has-text-centered buttons-container'> 
        <button className='button is-link is-light app-button mt-2' onClick={handleSubmit} id='generator-button'>
          <FontAwesomeIcon icon={faDiceD20} className='spaced-fa-icon' />
          <p id='generate-button-text'>{ t(`apps.${appName}.generate`).toUpperCase() }</p>
        </button>
        {
          values.outputList && appName === RANDPICKERAPP && values.chosen < values.outputList.length ?
          <button className='button is-link is-light app-button mt-2' onClick={extendPicked} id='generator-button'>
            <FontAwesomeIcon icon={faPlusCircle} className='spaced-fa-icon' />
            <p>{ t(`apps.${appName}.pick-more`).toUpperCase() }</p>
          </button>
          : 
          values.outputList && appName === RANDPICKERAPP &&
          <button className='button is-link is-light app-button mt-2' id='disabled-generator-button' disabled>
            <FontAwesomeIcon icon={faPlusCircle} className='spaced-fa-icon' />
            <p>{ t(`apps.${appName}.pick-more`).toUpperCase() }</p>
          </button>
        }
        {
          // The verify button is hidden until the user clicks on the generate button
          values.outputList &&
            <button id='verify-button' className='button is-link is-light app-button mt-2' onClick={showModal}>
              <FontAwesomeIcon icon={faQrcode} className='spaced-fa-icon' />
              <span>{ t('fast-verification.title').toUpperCase() }</span>
            </button>
        }
      </div>

      {
        inputStyle === KEYBOARD ?
          <SharingModal qrCode={qrCode} fileName={`QR_prng_v${values.code}`} url={url}/> :
        inputStyle === FILE ?
          <FileVerificationModal url={url} fileIn={values.fileInput} fileOut={values.outputFile} /> : ''
      }
    </div>
  )
}

export default ShuffleApp