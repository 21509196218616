import React from 'react'
import { useTranslation } from 'react-i18next';

import SummaryItems from './SummaryItems';
import Description from './Description';

import { LocalTranslationsContext } from '../Contexts';

const About = () => {

  const [ t ] = useTranslation('about');

  return (
    <LocalTranslationsContext.Provider value={[ t ]}>
      <div className='body-center container about-page'>
        <div className="box-content increased-padding">
          <h1>{t('title')}</h1>
          <hr />
          <div className="info-container">
            <SummaryItems />
          </div>
          <hr />
          <div className="info-container">
            <Description />
          </div>
        </div>
      </div>
    </LocalTranslationsContext.Provider>
  )
}

export default About;
