import { faChain, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useTranslation } from 'react-i18next';

const VerificationInfo = () => {

  const [ lt ] = useTranslation('apps');

  return (
    <div className='body-center container verification-detail-page'>
      <div className='box-content increased-padding'>
        <h1>{ lt('verify.page.title') }</h1>
        <hr />
        { lt('verify.page.intro') }
        <ul className='verification-modes'>
          {/* <li>
            <b className='verif-mode-title'><FontAwesomeIcon icon={faKeyboard} className='spaced-fa-icon'/>{ lt('verify.page.modes.manual.title') }</b>: { lt('verify.page.modes.manual.desc') }
          </li> */}
          <li>
            <b className='verif-mode-title'><FontAwesomeIcon icon={faChain} className='spaced-fa-icon'/>{ lt('verify.page.modes.link.title') }</b>: { lt('verify.page.modes.link.desc') }
          </li>
          <li>
            <b className='verif-mode-title'><FontAwesomeIcon icon={faQrcode} className='spaced-fa-icon'/>{ lt('verify.page.modes.qr.title') }</b>: { lt('verify.page.modes.qr.desc') }
          </li>
        </ul>
        { lt('verify.page.outro') }

        <h2 className='verification-title' id='seed'>{ lt('verify.page.seed.title') }</h2>
        <div>
          <p>{ lt('verify.page.seed.description') }</p>
          <br />
          <p>{ lt('verify.page.seed.code') }</p>
          <br />
          <div className='verif-code'>
            <code className='chainId-src'>{ lt('verify.page.seed.chainId') }</code> — <code className='pulseId-src'>{ lt('verify.page.seed.pulseId') }</code> — <code className='round-src'>{ lt('verify.page.seed.round') }</code>
          </div>
          <br />
          { lt('verify.page.seed.where') }
          <ol className='code-fields'>
            <li>
              <b>{ lt('verify.page.seed.chainId') }</b> { lt('verify.page.seed.and') } <b>{ lt('verify.page.seed.pulseId') }</b> { lt('verify.page.seed.pulse-description') }
            </li>
            <li>
              <b>{ lt('verify.page.seed.round') }</b> { lt('verify.page.seed.round-description') }
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default VerificationInfo