import il1 from '../assets/images/about/il1.png';
import il2 from '../assets/images/about/il2.png';
import il3 from '../assets/images/about/il3.png';
import il4 from '../assets/images/about/il4.png';

export const aboutSummaryItems = [
    {
        color: 'red',
        img: il1,
        number: 1,
        textSrc: 'randomness',
    },
    {
        color: 'yellow',
        img: il2,
        number: 2,
        textSrc: 'democracy',
    },
    {
        color: 'blue',
        img: il3,
        number: 3,
        textSrc: 'beacon',
    },
    {
        color: 'cyan',
        img: il4,
        number: 4,
        textSrc: 'audit',
    }
]