/**
 * Obtains css classes depending the theme selected
 * @param {string} theme light | full-height | default
 * @returns 
 */
export const getAppClassTheme = (theme) => {
  switch (theme){
    case 'light':
      return {
        appContainer: 'app-container-light',
        codeContainer: 'code-container-light',
        pulseContainer: 'pulse-container-light',
        resultContainer: 'result-container-light',
      }
    
    case 'full-height':
      return {
        appContainer: 'app-container-default-full-height',
        codeContainer: 'code-container-default',
        pulseContainer: 'pulse-container-default',
        resultContainer: 'result-container-default',
      }

    default:
      return {
        appContainer: 'app-container-default',
        codeContainer: 'code-container-default',
        pulseContainer: 'pulse-container-default',
        resultContainer: 'result-container-default',
      }
  }
}

export const pastelColors = [
  { name: "Pastel Pink", hex: "#FFD9DF" },
  { name: "Pastel Blue", hex: "#C8DFE0" },
  { name: "Pastel Green", hex: "#D3E8B8" },
  { name: "Pastel Purple", hex: "#D9CCDF" },
  { name: "Pastel Yellow", hex: "#FCFCB5" },
  { name: "Pastel Orange", hex: "#FFC08D" },
  { name: "Pastel Mint", hex: "#BFFFBF" },
  { name: "Pastel Lavender", hex: "#F0F0FA" },
  { name: "Pastel Coral", hex: "#FFA69A" },
  { name: "Pastel Lilac", hex: "#DACFDA" },
  { name: "Pastel Sky Blue", hex: "#B3E2E9" },
  { name: "Pastel Lemon", hex: "#FEFFD3" },
  { name: "Pastel Rose", hex: "#FFE7E1" },
  { name: "Pastel Aqua", hex: "#B2FFFF" },
  { name: "Pastel Gold", hex: "#E8D8AF" },
];