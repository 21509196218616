import { faCircleCheck, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SharingModal = ({qrCode, fileName, url}) => {

  const [ t ] = useTranslation('apps');

  const closeModal = () => {
    document.querySelector('body').classList.remove('block-scroll');
    document.querySelectorAll('.modal').forEach((modal) => modal.classList.remove('is-active'));
  }

  const copyToClipboard = () => {
    const button = document.getElementById('copy-button');
    button.disabled = true
    navigator.clipboard.writeText(url);
    button.lastChild.textContent = t('fast-verification.copied');
    setTimeout(() => {
      button.lastChild.textContent = t('fast-verification.copy');
      button.disabled = false;
    }, 1000);
  }


  const downloadQR = () => {
    qrCode.download({
      extension: 'png',
      name: fileName
    });
  }

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      const e = event || window.event;
      if (e.key === 'Escape') { // Escape key
        closeModal();
      }
    });
    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
      $close.addEventListener('click', () => {
        closeModal();
      });
    });
    document.querySelectorAll('#qr-code').forEach((qrElement) => {
      qrCode.append(qrElement);
    });
  }, [qrCode])

  return (
    <div className='modal modal-qr'>
      <div className='modal-background'></div>
      <div className='modal-content'>
        <div className='modal-box'>
          <div className='modal-content-qr'>
            <div>
              <div id='qr-code' />
            </div>
            <div className='modal-description-qr'>
              <h1 className='modal-title'>{ t('fast-verification.title') }</h1>
              {
                url.length <= 400 ? 
                  <p>{ t('fast-verification.desc') }</p> : 
                  <p>{ t('fast-verification.desc-no-qr') }</p>
              }
            </div>
          </div>
          <div className='modal-buttons'>
            <button className='button is-light is-link app-button mt-3' id='copy-button' onClick={copyToClipboard}>
              <FontAwesomeIcon icon={faCopy} className='spaced-fa-icon'/>
              <p>{ t('fast-verification.copy') }</p>
            </button>
            {
              url.length <= 400 &&
              <button className='button is-light is-link app-button mt-3' onClick={downloadQR}>
                <FontAwesomeIcon icon={faDownload} className='spaced-fa-icon' />
                <p>{ t('fast-verification.download') }</p>
              </button>
            }
            <button id='verify-button' className='button is-link is-light app-button mt-3' onClick={() => window.open(url)}>
              <FontAwesomeIcon icon={faCircleCheck} className='spaced-fa-icon' />
              <span>{ t('fast-verification.verify') }</span>
            </button>
            {/* TODO: Fix buttons position */}
          </div>
        </div>
      </div>
      <button className='modal-close is-large' aria-label='close'></button>
    </div>
  )
}


export default SharingModal