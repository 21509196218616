import React, { useContext } from 'react';

import { GlobalTranslationsContext } from '../Contexts';
import logoRandomUchile from '../../assets/images/logolight.svg'

const Quote = () => {

  const [ t ] = useContext(GlobalTranslationsContext);

  return (
    <div className='quote-container'>
      <div>
        "{ t('home.quote') }" <br />
        <img src={ logoRandomUchile } className='logo-white' alt='Random UChile Logo' /> 
      </div>
    </div>
  )
}

export default Quote