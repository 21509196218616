import React, { useEffect } from 'react';
const NumInput = ({label, number, setNumber, error, errorMsg }) => {

  useEffect(() => {
    if (error) {
      document.querySelector('#choose-quantity').classList.add('is-danger');
      document.querySelector('.num-input-error').classList.remove('is-hidden');
      document.querySelector('.custom-input').style.borderColor = '#ff3860';
    } else {
      document.querySelector('#choose-quantity').classList.remove('is-danger');
      document.querySelector('.num-input-error').classList.add('is-hidden');
      document.querySelector('.custom-input').style.borderColor = '#3273dc';
    }
  }, [error]);

  return (
    <div className='has-text-centered mt-3 mb-5'>
      <div className='control custom-input custom-input-light' id='choose-quantity'>
        <label>{ label }</label>
        <input className='input' type='number' value={number} onChange={(e) => setNumber(e.target.value)} />
      </div>
      <p className='help num-input-error is-danger'>{ errorMsg }</p>
    </div>
  )
}

export default NumInput