export const getPulseParams = (pulse, loading) => {
  if (loading || Object.entries(pulse).length === 0) {
    return {
      date: new Date().toLocaleDateString('es', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }),
      time: new Date().toString().substring(16, 21),
      chainId: 1,
      pulseId: 1,
    }
  } else {
    const pulseDate = new Date(pulse.timeStamp);
    return {
      date: pulseDate.toLocaleDateString('es', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }),
      time: pulseDate.toString().substring(16, 21),
      chainId: pulse.chainIndex,
      pulseId: pulse.pulseIndex,
    }
  }
}

export const dateToHtml = (timestamp) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', hour: '2-digit', minute: '2-digit', second: '2-digit' }
  const date = new Date(timestamp);
  const locale = localStorage.getItem('lang') === 'es' ?  'es-CL' : 'en-US'
  return date.toLocaleDateString(locale, options) + " UTC";
}

export const localDateToHtml = (timestamp) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
  const date = new Date(timestamp);
  const locale = localStorage.getItem('lang') === 'es' ?  'es-CL' : 'en-US'
  return date.toLocaleDateString(locale, options) + ' ' + (localStorage.getItem('lang') === 'es' ?  '(Hora Local)' : '(Local Time)');
}