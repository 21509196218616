import React, { useContext } from 'react';
import { LocalTranslationsContext } from '../Contexts';

const SummaryItem = ({color, img, number, textSrc}) => {

  const [ t ] = useContext(LocalTranslationsContext);

  return (
    <div className='is-flex is-justify-content-space-around is-flex-wrap-wrap summary-item'>
      <div className={`summary-item-number ${color}-color`} >
        {number}.
      </div>
      <div className='summary-text-container'>
        {t(`summary.${textSrc}`)}
      </div>
      <div>
        <img src={img} alt={textSrc} className='summary-image' />
      </div>
    </div>
  )
}

export default SummaryItem