import settings from '../settings.json';

const mainURI = settings.beaconURI;

// Example POST method implementation:
export async function getData(url = "") {
  // Default options are marked with *
  const response = await fetch(url);
  return response.json(); // parses JSON response into native JavaScript objects
}

/**
 * Async function that makes the request for a pulse by ID.
 * @returns {json} the pulse
 */
export const getLastPulse = async () => {
	const url = `${mainURI}/pulse`;
	const response = await fetch(url);
	const { pulse } = await response.json();
	return pulse
}

export const getServerTime = async () => {
	const url = `${mainURI}/time`;
	const serverTime = await fetch(url);
  return serverTime;
}

/**
 * Async function that makes the request for a pulse by ID.
 * @param {string} chainId 
 * @param {string} pulseId 
 * @returns {json} the pulse
 */
export const getPulse = async (chainId, pulseId) => {
	const url = `${mainURI}/chain/${chainId}/pulse/${pulseId}`;
	const response = await fetch(url);
	if (response.status !== 200) {
		return {
			status: response.status,
			message: response.statusText
		}
	}
	const { pulse } = await response.json();
	return pulse
}

/**
 * Async function that makes the request for external values by pulse ID.
 * @param {string} chainId 
 * @param {string} pulseId 
 * @returns {json} the pulse
 */
export const getExternalValues = async (chainId, pulseId) => {
	const url = `${mainURI}/external/detail?chainId=${chainId}&pulseId=${pulseId}`;
	const response = await fetch(url);
	if (response.status !== 200) {
		return {
			status: response.status,
			message: response.statusText
		}
	}
	const { externalValueDetail } = await response.json();
	return externalValueDetail
}

/**
 * Async function that makes the request for a pulse by ID.
 * @param {string} chainId 
 * @param {string} pulseId 
 * @returns {json} the pulse
 */
 export const getPulseByTimeStamp = async (timestamp) => {
	const url = `${mainURI}/pulse?timeGE=${timestamp}`;
	const response = await fetch(url);
	if (response.status !== 200) {
		return {
			status: response.status,
			message: response.statusText
		}
	}
	const { pulse } = await response.json();
	return pulse
}