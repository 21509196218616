import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LocalTranslationsContext } from '../Contexts'

const AppItem = ({name, img, route, isExternal = false}) => {

	const [ t ] = useContext(LocalTranslationsContext);

  if (isExternal){
    return (
      <>
        <div className='desktop-app'>
          <a href={route} target='_blank' rel='noreferrer'>
            <div className='app-item'>
              <img alt={ t(`apps.${name}.title`) } className='app-img' src={img} />
              <div className='app-desc circular-size'>
                <p>
                  { t(`apps.${name}.description`) }
                </p>
              </div>
            </div>
          </a>
          <div className='app-name'>
            { t(`apps.${name}.title`) }
          </div>
        </div>
        <a href={route} className='mobile-app has-text-centered' rel='noreferrer'>
          <div>
            <img alt={ t(`apps.${name}.title`) } className='app-img' src={img} />
          </div>
          <div className='mobile-app-desc'>
            <h2>{ t(`apps.${name}.title`) }</h2>
            <p>{ t(`apps.${name}.description`) }</p>
          </div>
        </a>
      </>
    )
  }

  return (
		<>
			<div className='desktop-app'>
				<Link to={route}>
					<div className='app-item'>
						<img alt={ t(`apps.${name}.title`) } className='app-img' src={img} />
						<div className='app-desc circular-size'>
							<p>
								{ t(`apps.${name}.description`) }
							</p>
						</div>
					</div>
				</Link>
				<div className='app-name'>
					{ t(`apps.${name}.title`) }
				</div>
			</div>
			<Link to={route} className='mobile-app has-text-centered'>
				<div>
					<img alt={ t(`apps.${name}.title`) } className='app-img' src={img} />
				</div>
				<div className='mobile-app-desc'>
					<h2>{ t(`apps.${name}.title`) }</h2>
					<p>{ t(`apps.${name}.description`) }</p>
				</div>
			</Link>
		</>
  )
}

export default AppItem