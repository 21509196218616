import logoQR from '../assets/images/logoQR.svg';
import QRCodeStyling from 'qr-code-styling';

// https://codesandbox.io/s/qr-code-styling-react-example-l8rwl?file=/src/App.js
export const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  data: '',
  margin: 0,
  qrOptions: {
    typeNumber: '0',
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 0
  },
  dotsOptions: {
    type: 'rounded',
    color: '#485fc7'
  },
  backgroundOptions: {
    'color': '#ffffff'
  },
  image: logoQR,
  dotsOptionsHelper: {
    colorType: {
      single: true,
      gradient: false
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#6a1a4c',
      color2: '#6a1a4c',
      rotation: 0
    }
  },
  cornersSquareOptions: {
    type: '',
    color: '#485fc7'
  },
  cornersSquareOptionsHelper: {
    colorType: {
      single: true,
      gradient: false
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#000000',
      color2: '#000000',
      rotation: 0
    }
  },
  cornersDotOptions: {
    type: '',
    color: '#485fc7'
  },
  cornersDotOptionsHelper: {
    colorType: {
      single: true,
      gradient: false
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#000000',
      color2: '#000000',
      rotation: '0'
    }
  },
  backgroundOptionsHelper: {
    colorType: {
      single: true,
      gradient: false
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#ffffff',
      color2: '#ffffff',
      rotation: 0
    }
  }
});
