import React from 'react';
import { Link } from 'react-router-dom';

const GeneralPublicUsing = ({t}) => {
  return (
    <div>
      <h2>{ t('general.title') }</h2>
      <p>
        { t('general.description.paragraph-1') + ' '}
        (<Link to='/apps'>
          { t('general.description.apps') }
        </Link>). { t('general.description.paragraph-2') }
      </p>
    </div>
  )
}

export default GeneralPublicUsing