import React from 'react';
import { useTranslation } from 'react-i18next';

import GeneralPublicUsing from './GeneralPublicUsing';
import OrganizationsUsing from './OrganizationsUsing';
import DevelopersUsing from './DevelopersUsing';

const UsingScreen = () => {

  const [ t ] = useTranslation('using');

  return (
    <div className='body-center container using-page'>
      <div className='box-content increased-padding'>
        <h1>{ t('title') }</h1>
        <hr />
        <GeneralPublicUsing t={t} />
        <OrganizationsUsing t={t} />
        <DevelopersUsing t={t} />
      </div>
    </div>
  )
}

export default UsingScreen