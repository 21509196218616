import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import { validateValues, validateVerificationCode, validateVerificationCodes } from '../../../utils/validators';
import { recreateResult, compareResults } from '../../../utils/randomness';

import loadingGif from '../../../assets/images/loading.gif';
import { BINGOAPP } from '../../../utils/utils';

const VerificationBox = ({values}) => {

  const [ t ] = useTranslation('apps');
  const [ status, setStatus ] = useState('error');

  const validateResult = useCallback( () => {
    if (!validateValues(values) || values.output === '' || 
          (values.app !== BINGOAPP && !validateVerificationCode(values.code)) ||
          (values.app === BINGOAPP && !validateVerificationCodes(values.codes))) {
      setStatus('error');
      return;
    }

    setStatus('loading');

    setTimeout( () => {
      recreateResult(values)
        .then( result => {
          if (compareResults(values.app, result, values.output)) {
            setStatus('success');
          } else {
            setStatus('incorrect');
          }
        }
      ); 
    }, 500);
  }, [values]);

  useEffect(() => {
    validateResult();
  }, [validateResult]);

  return (
    <>
      { 
        status === 'success' ?
        <div className='verification-container is-success' id=''>
          <span className='verification-icon'><FontAwesomeIcon icon={faCircleCheck} /></span>
          <span>{ t('apps.verify.verified') }</span>
        </div> :
        status === 'incorrect' ?
        <div className='verification-container is-danger' id=''>
          <span className='verification-icon'><FontAwesomeIcon icon={faCircleXmark} /></span>
          <span>{ t('apps.verify.bad-result') }</span>
        </div> :
        status === 'error' ? 
        <div className='verification-container is-warning' id=''>
          <span className='verification-icon'><FontAwesomeIcon icon={faCircleExclamation} /></span>
          <span id='' className=''>{ t('apps.verify.error') }</span>
        </div> :
        status === 'loading' ?
        <div className='verification-container is-link' id=''>
          <img src={loadingGif} className='loading-icon' alt='...loading'/>
        </div> : ''
      }       
    </>
  )
}

export default VerificationBox