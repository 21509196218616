import React from 'react'
import { useTranslation } from 'react-i18next';

import AppItem from './AppItem';
import { appsList, externalAppsList } from '../../data/appsList';
import { LocalTranslationsContext } from '../Contexts';

import VerifiabilityAccordion from './VerifiabilityAccordion';

const Apps = () => {

  const [t] = useTranslation('apps');

  return (
    <LocalTranslationsContext.Provider value={[ t ]}>
      <div className='body-center container apps-main-page'>
        <div className='box-content'>
          <h1>{t('title')}</h1>
          <hr />
          <div className='is-flex is-justify-content-space-around is-flex-wrap-wrap'>
            {
              appsList.map( app => (
                <AppItem name={app.name} img={app.img} route={app.route} key={app.name} />
              ))
            }
          </div>
          <div className='verifiability'>
            <VerifiabilityAccordion />
          </div>
          <div className='external-apps-container'>
            <h2 className='external-apps-title'>{ t('external.title') }</h2>
            <div className='is-flex is-justify-content-space-around is-flex-wrap-wrap'>
              {
                externalAppsList.map( app => (
                  <AppItem name={app.name} img={app.img} route={app.route} key={app.name} isExternal={true} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </LocalTranslationsContext.Provider>
  )
}

export default Apps