import React from 'react';
import SummaryItem from './SummaryItem';

import { aboutSummaryItems } from '../../data/aboutSummaryItems';

const SummaryItems = () => {

  return (
		<div className='is-flex is-justify-content-space-around is-flex-wrap-wrap summary-container'>
			{
				aboutSummaryItems.map( (sumItem) => (
					<SummaryItem
						color={sumItem.color}
						key={sumItem.number}
						img={sumItem.img}
						number={sumItem.number}
						textSrc={sumItem.textSrc}
					/>
				))
			}
		</div>
  )
}

export default SummaryItems