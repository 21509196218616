export const externalSources = [
  {
    title: 'earthquakes',
    color: '#042568',
    url: 'https://www.sismologia.cl/',
  },
  {
    title: 'radio',
    color: '#10387e',
    url: 'https://radio.uchile.cl/',
  },
  {
    title: 'ethereum',
    color: '#131313',
    url: 'https://www.ethereum.org',
  },
  {
    title: 'buses',
    color: '#c80f2e',
    url: 'https://www.red.cl/',
  }
]