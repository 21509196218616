import React from 'react';
import { useTranslation } from 'react-i18next';

import MainBreadcrumb from '../../../components/MainBreadcrumb';
import PulseCountdown from '../../../components/PulseCountdown';
import Breadcrumb from '../Breadcrumb';
import BingoApp from './BingoApp';

const Bingo = () => {

  const [ t ] = useTranslation('apps');

  const breadCrumbsList = [
    <Breadcrumb t={t} />,
    <div className='breadcrumb-current'>
      <span>{ t('apps.bingo.title') }</span>
    </div>
  ]

  return (
    <div className='container app-page'>
      <div className='app-container'>
        <div className='breadcrumbs'>
          <MainBreadcrumb breadCrumbsList={breadCrumbsList} />
        </div>
        <div className='mini-countdown is-hidden'>
          <PulseCountdown isMini={true} />
        </div>
        <div className='app-content'>
          <div className='application bingo-app'>
            <BingoApp />
          </div>
          <div className='pulse-countdown'>
            <PulseCountdown colorTheme='full-height' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bingo