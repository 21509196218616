import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalTranslationsContext, BeaconContext } from '../../Contexts';
import { validatePRNGInput } from '../../../utils/validators';

import loadingGif from '../../../assets/images/loading.gif';
import { getUniverse, splitListDots } from '../../../utils/randomness';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faDiceD20, faQrcode } from '@fortawesome/free-solid-svg-icons';

import { qrCode } from '../../../utils/verification';
import SharingModal from '../SharingModal';
import { NUMBERSAPP, MAX_VALUE, buildUrl, showModal } from '../../../utils/utils';


const RandomNumbersApp = () => {

  const { chacha, pulse } = useContext(BeaconContext);
  const [ tg ] = useContext(GlobalTranslationsContext);
  const [ t ] = useTranslation('apps');

  const [ min, setMin ] = useState(1);
  const [ max, setMax ] = useState(10);
  const [ num, setNum ] = useState(6);

  const [ url, setUrl ] = useState('');
  const [ error, setError ] = useState('');
  const [ verCode, setVerCode ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ numbers, setNumbers ] = useState(undefined);
  const [ counter, setCounter ] = useState(1);

  const loadingState = () => {
    setLoading(true);
    setNumbers(undefined);
    setError('');
  }

  const updateQRCode = (url) => {
    const size = url.length * 0.8 > 250 ? url.length * 0.8 : 250;
    if (numbers !== undefined) {
      url.length <= 400 ?
        qrCode.update({ data: url, width: size, height: size}) : qrCode.update({ data: '' });
    }
  }

  /**
   * @description Handles the submit of a form and generates N random numbers
   * @param e the Event 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    loadingState();
    const genButton = document.getElementById('generator-button');
    genButton.disabled = true;

    const numNum = parseInt(num);
    const numMin = parseInt(min);
    const numMax = parseInt(max);
      
    setTimeout( () => {
      if (isNaN(numNum) || isNaN(numMin) || isNaN(numMax))
        setError( t('apps.numbers.blank-error') )
      else if (Math.abs(numMin) > MAX_VALUE || Math.abs(numMax) > MAX_VALUE)
        setError( t('apps.numbers.max-error') );
      else if (numNum > 1000)
        setError( t('apps.numbers.num-error') );
      else if (!validatePRNGInput(numNum, numMin, numMax))
        setError( t('apps.numbers.default-error') );
      else if (numMax - numMin >= 500000)
        setError( t('apps.numbers.range-error') );
      else {
        setCounter((counter) => counter + 1);

        const verificationCode = pulse.chainIndex !== undefined ? 
          `${pulse.chainIndex}-${pulse.pulseIndex}-${counter}` 
          : 'Pulse Unavailable, Reload!';

        const universe = getUniverse(numMin, numMax);
        const output = chacha.sample(universe, numNum);

        const newUrl =buildUrl(NUMBERSAPP, {
          code: verificationCode,
          output: output,
          nums: numNum,
          min: numMin,
          max: numMax,
        });

        setUrl(newUrl);
        updateQRCode(newUrl);

        setVerCode(verificationCode);
        setNumbers(output);
        genButton.childNodes[1].innerText = t('apps.numbers.regenerate');
      }
      setLoading(false);
      genButton.disabled = false;
    }, 500);
  }

  const reseedAndReset = useCallback(() => {
    chacha.reseed(pulse.outputValue);
    setCounter(1);
  }, [chacha, pulse]);

  useEffect(() => {
    reseedAndReset(); // reseed whenever the input changes    
  }, [num, min, max, reseedAndReset]);

  return (
    <div className='app-container-light prng-main'>
      <h1>{ t('apps.numbers.title') }</h1>

      <div className='input-container random-numbers-inputs'>

        <div className='has-text-centered n-input'>
          <div className='control custom-input custom-input-light'>
            <label>{ t('apps.numbers.nums') }</label>
            <input className='input' type='number' value={num} onChange={(e) => setNum(e.target.value)} />
          </div>
        </div>

        <div className='has-text-centered range-input'>
          <div className='control custom-input custom-input-light'>
            <label>{ t('apps.numbers.min') }</label>
            <input className='input' type='number' value={min} onChange={(e) => setMin(e.target.value)}/>
          </div>
        </div>

        <div className='has-text-centered range-input'>
          <div className='control custom-input custom-input-light'>
            <label>{ t('apps.numbers.max') }</label>
            <input className='input' type='number' value={max} onChange={(e) => setMax(e.target.value)}/>
          </div>
        </div>

      </div>

      {
        loading ?
        <div className='result-box result-container-light' id='generator-result'>
          <img src={loadingGif} className='loading-icon' alt='...'/>
        </div> : ''
      }
      {
        error !== '' &&
        <div className='result-box bad-input'>
          { error } 
        </div>
      }
      {
        numbers !== undefined &&
        <>
          <div className='result-box result-container-light' id='generator-result'>
            { splitListDots(numbers) }
          </div>
          <div className='code-container-light' id='code-container'>
            <span id='verification-code' className='verification-code'>{ tg('home.prng.code-msg') }: <b>{ verCode }</b></span>
            <div className='result-tooltip'>
              <FontAwesomeIcon icon={faCircleQuestion} />
              <span className='tooltiptext'>{ tg('home.prng.tooltip') }</span>
            </div>
          </div>
        </>
      }

      <div className='has-text-centered buttons-container'> 
        <button className='button is-link is-light app-button' onClick={handleSubmit} id='generator-button'>
          <FontAwesomeIcon icon={faDiceD20} className='spaced-fa-icon' />
          <p>{ t('apps.numbers.generate') }</p>
        </button>
        {
          // The verify button is hidden until the user clicks on the generate button
          numbers !== undefined &&
            <button id='verify-button' className='button is-link is-light app-button' onClick={showModal}>
              <FontAwesomeIcon icon={faQrcode} className='spaced-fa-icon' />
              <span>{ t('fast-verification.title').toUpperCase() }</span>
            </button>
        }
      </div>

      <SharingModal qrCode={qrCode} fileName={`QR_lottery_v${verCode}`} url={url}/>
      
    </div>
  )
}

export default RandomNumbersApp