import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { rebuildFile } from '../../../../utils/fileHandlers';
import { pastelColors } from '../../../../utils/customizeStyle';
import { downloadFile, BINGOAPP, FILE, KEYBOARD, NUMBERSAPP, RANDPICKERAPP, SHUFFLEAPP } from '../../../../utils/utils';

function splitIntoSpan(text, chosen, separator, randColors = true) {
	let splittedText = text.split(separator)
	if (chosen !== -1) {
		splittedText = splittedText.slice(0, chosen)
	}
	let colorRange = pastelColors.length;
	return (
		<div className='shuffled-items'>
			{ splittedText.map((line, index) => {
				let colorNum = randColors ? pastelColors[index % colorRange].hex : '#999999'
				return (
					<div className='shuffled-item' key={index} style={{'--bgcolor': colorNum}}>
						{index+1}. {line}
					</div>
				)
			}) }
		</div>
	)
}

const ShowContent = ({values}) => {

  const [ t ] = useTranslation('apps');
	const [ files, setFiles ] = useState({});

	useEffect(() => {
		setFiles({
			inFile: rebuildFile(values.inFilename, values.rawInput.split('\n')),
			outFile: rebuildFile(values.outFilename, values.output.split('\n'))
		})

		return () => setFiles({});
	}, [setFiles, values])

  return (
    <div className='current-content'>
			<h2>{ t('apps.fast-verification.summary') }</h2>
			<p>
				<b>{ t('apps.verify.current.app') }: </b>
      	{ values.app ? t(`apps.${values.app}.title`) : t('apps.fast-verification.unknown') }
			</p>
			<p>
			{
				values.app !== BINGOAPP ?
					<>
						<b>{ t('apps.verify.current.code') }: </b>
						{ values.code !== '' ? values.code : t('apps.fast-verification.unknown') }
					</>
				:
					<>
						<b>{ t('apps.verify.current.codes') }: </b>
						{ values.codes !== '' ? values.codes.replaceAll(',', ', ') : t('apps.fast-verification.unknown') }
					</>
			}
			</p>
			
			{	// Show content depending on the app
				values.app === NUMBERSAPP ?
					values.nums !== '' && values.min !== '' && values.max !== '' &&
						<p>
							<b>{ t('apps.verify.current.select') }: </b> 
							{ values.nums } {values.nums === '1' ? t('apps.verify.current.number') : t('apps.verify.current.numbers') } { values.min } { t('apps.verify.current.and') } {values.max}
						</p>
					:
				values.app === RANDPICKERAPP || values.app === SHUFFLEAPP ?
					{
						[KEYBOARD]: (
							values.rawInput &&
							<p>
								<b>{ t('apps.verify.current.input') }: </b>
								{ splitIntoSpan(values.rawInput, -1, '\n', false) }
							</p>
						),
						[FILE]: (
							values.inFilename &&
							<p>
								<b>{ t('apps.verify.current.file-input') }: </b> 
								<span className='fast-verification-file' onClick={() => downloadFile(files.inFile)}>{ values.inFilename }</span>
							</p>
						)
					}[values.inStyle]
					:
				values.app === BINGOAPP && ''
			}

			<p>
				<b>{ t('apps.verify.current.output') }: </b>
				{ // OUTPUT FIELD
					values.output ?
						values.app === SHUFFLEAPP && values.inStyle === FILE &&	values.outFilename !== '' ?
							<span className='fast-verification-file' onClick={() => downloadFile(files.outFile)}>{ values.outFilename }</span>
							:
						values.app === RANDPICKERAPP ?
							splitIntoSpan(values.output, values.chosen, '\n')
							:
						splitIntoSpan(values.output, -1, '\n') 
						: 
					t('apps.fast-verification.unknown') 
				}
			</p>
    </div>
  )
}
export default ShowContent