import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRouter } from './AppRouter';
import { BeaconContext, GlobalTranslationsContext } from './pages/Contexts';
import { getLastPulse } from './utils/beacon';

export const RandomUChile = () => {

	// Creates a Single instance of ChaChaRand that will be used further
	const { ChaChaRand } = require('./utils/chacha/chacha20-generator');
	const chacha = useMemo(() => new ChaChaRand("00000000000000000000000000000000000000000000000000000000000000000000000000000000"), [ChaChaRand]);

  // Minute constant used to get the pulse on change
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ firstLoad, setFirstLoad ] = useState(true);
  const [ pulse, setPulse ] = useState({});

  /**
   * @description Updates the pulse after 60 seconds.
   */
  const updateBeaconPulse = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      getLastPulse()
        .then(pulse => {
          if (!pulse || Object.keys(pulse).length === 0) {
            console.error('No pulse response');
            return;
          }
          setPulse(pulse);
          chacha.reseed(pulse.outputValue)
          console.log('PRNG reseeded with: ' + pulse.outputValue)
          setLoading(false);
          setError(false);
        })
        .catch(error => {
          console.error(error);
          chacha.reseed()
          console.warn('PRNG reseeded with zero, randomness is not guaranteed!')
          setLoading(false);
          setError(true);
        })  
    }, 250);
  }, [chacha])

  /**
   * @description Updates the pulse every second at 00' second mark.
   */
  const pulseUpdaterTimer = useCallback(() => {
    const currSeconds = (new Date()).getSeconds() % 60;
    if (currSeconds === 59 || firstLoad) {
      updateBeaconPulse();
      setFirstLoad(false);
    }
  }, [updateBeaconPulse, firstLoad])


  /**
   * @description Programs a timer to update the pulse at 00' second mark.
   */
  useEffect( () => {
    const timerInterval = setInterval(pulseUpdaterTimer, 1000);
		return () => { 
      clearInterval(timerInterval);
		}
	}, [pulseUpdaterTimer])


  // Shared information among components
  const beaconProvider = {
    chacha: chacha,
    pulse: pulse,
    loading: loading || Object.keys(pulse).length === 0,
    error: error,
  }

	return (
		<BeaconContext.Provider value={ beaconProvider }>
			<GlobalTranslationsContext.Provider value={ useTranslation('global') }>
        <AppRouter />
			</GlobalTranslationsContext.Provider>
		</BeaconContext.Provider>
	)
}
