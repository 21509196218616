import React, { useContext } from 'react';
import { GlobalTranslationsContext } from './pages/Contexts';
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";

// Data
import { menuItems } from './data/menuItems';
import { appsList } from './data/appsList';

// Pages
import Home from './pages/Home';
import FastVerify from './pages/Apps/Verification/FastVerification';
import LeagueOfEntropy from './pages/Projects/LeagueOfEntropy';
import VerificationInfo from './pages/Apps/VerificationInfo';
import NotFound from './pages/NotFound';

// Components
import NavBar from './components/Navbar/NavBar';
import Footer from './components/Footer';
import EntryPage from './components/MarkdownEntries/EntryPage';
import ViewerAdvanced from './pages/Beacon/Viewers/ViewerAdvanced';
import ViewerSimple from './pages/Beacon/Viewers/ViewerSimple';

import NewsBreadcrumb from './pages/News/Breadcrumb';
import ProjectsBreadcrumb from './pages/Projects/Breadcrumb';

// Markdown entries JSON
import newsJSON from './markdown/news/entries.json';
import projectsJSON from './markdown/projects/entries.json';


export const AppRouter = () => {

	const [ t ] = useContext(GlobalTranslationsContext);
	const newsEntries = newsJSON.entries.filter( (entry) => entry.route !== undefined && entry.file !== undefined);
	const projectsEntries = projectsJSON.entries.filter( (entry) => entry.route !== undefined && entry.file !== undefined);

  return (
		<Router>
			<div className='main-container'>
				<NavBar />
				<Routes>
					<Route exact path='/' element={<Home/>} />
					{ // Pages in Navbar
						menuItems.map( ({url, component}) => (
							<Route exact path={url} element={component} key={url} />
						))
					}
					{ // Apps in app section
						appsList.map( ({route, component}) => (
							<Route exact path={route} element={component} key={route} />
						))
					}
					{ // News publications
						newsEntries.map( (entry) => (
							<Route exact path={entry.route} element={<EntryPage entry={entry} breadcrumb={<NewsBreadcrumb t={t} />}/>} key={entry.route} />
						))
					}
					{ // Projects related pages
						projectsEntries.map( (entry) => (
							<Route exact path={entry.route} element={<EntryPage entry={entry} breadcrumb={<ProjectsBreadcrumb t={t} />}/>} key={entry.route} />
						))
					}
					{/* Others */}
					<Route exact path='/apps/fast-verification' element={<FastVerify />} />
					<Route exact path='/apps/verification' element={<VerificationInfo />} />
					<Route exact path='/randomness-beacon/viewer' element={<ViewerSimple />} />
					<Route exact path='/randomness-beacon/advanced-viewer' element={<ViewerAdvanced/>} />
					<Route exact path='/projects/league-of-entropy' element={<LeagueOfEntropy />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</div>
			<Footer />
		</Router>
  )
}
