// Pictures for apps
import ImgNumbersApp from '../assets/images/apps/dice.jpg';
import ImgShuffleApp from '../assets/images/apps/shuffle.jpg';
import ImgPickerApp from '../assets/images/apps/rand-picker.jpg';
import ImgBingoApp from '../assets/images/apps/lottery.jpg';
import Congress from '../assets/images/apps/congress.jpg';

// Apps components
import RandomPicker from '../pages/Apps/RandomPicker';
import RandomNumbers from '../pages/Apps/RandomNumbers';
import Shuffle from '../pages/Apps/Shuffle';
import Bingo from '../pages/Apps/Bingo';

// Apps names
import { BINGOAPP, RANDPICKERAPP, NUMBERSAPP, SHUFFLEAPP } from '../utils/utils';

export const appsList = [
    {
        component: <RandomNumbers />,
        name: NUMBERSAPP,
        img: ImgNumbersApp,
        route: '/apps/numbers',
    },
    {
        component: <RandomPicker />,
        name: RANDPICKERAPP,
        img: ImgPickerApp,
        route: '/apps/picker',
    },
    {
        component: <Shuffle />,
        name: SHUFFLEAPP,
        img: ImgShuffleApp,
        route: '/apps/shuffle',
    },
    {
        component: <Bingo />,
        name: BINGOAPP,
        img: ImgBingoApp,
        route: '/apps/bingo',
    }
]

export const externalAppsList = [
    {
        name: 'deputy',
        img: Congress,
        route: 'https://diputado.labs.clcert.cl/',
    },
    // {
    //     name: 'movie',
    //     img: Cinema,
    //     route: '/apps',
    // },
    // {
    //     name: 'comments',
    //     img: Instagram,
    //     route: '/apps',
    // },
]