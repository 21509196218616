import React from 'react';

import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const KeyboardOutput = ({values, t, tg}) => {

  return (
    <div className='has-text-centered'>
      <label>
        { t('apps.shuffle.your-list') } 
        <b> { values.outputList.length } { t('apps.shuffle.elements') } </b>
        { t('apps.shuffle.been-shuffled') }.
      </label>
      <div className='shuffled-container keyboard-output' id='shuffled-result'>
        <ol>
          { values.outputList.map((row, index) => <li key={index}>{row}</li>) }
        </ol>
      </div>
      <div className='code-container-light' id='code-container'>
        <span id='verification-code' className='verification-code'>
          { tg('home.prng.code-msg') }: <b>{ values.code }</b>
        </span>
        <div className='result-tooltip'>
          <FontAwesomeIcon icon={faCircleQuestion} />
          <span className='tooltiptext'>{ tg('home.prng.tooltip') }</span>
        </div>
      </div>
    </div>
  )
}

export default KeyboardOutput