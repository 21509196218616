import settings from '../settings.json';

export const KEYBOARD = 'keyboard';
export const FILE = 'file';

export const NUMBERSAPP = 'numbers';
export const RANDPICKERAPP = 'picker';
export const SHUFFLEAPP = 'shuffle';
export const BINGOAPP = 'bingo';
export const DRAWAPP = 'draw';

export const MAX_VALUE = 9999999;

export const INT_REGEXP = /^\+?(0|[1-9]\d*)$/


/**
 * @description Toggles the dropdown
 * @param e the Event 
 */
export const toggleDropDown = (e) => {
  e.stopPropagation();
  document.querySelector('.dropdown').classList.toggle('is-active');
}

/**
 * @description Toggles the dropdown
 * @param e the Event 
 */
 export const toggleAccordion = (e) => {
  e.stopPropagation();
  document.querySelector('.accordion').classList.toggle('is-active');
}

/**
 * @description Hides the dropdown
 * @param e the Event 
 */
export const closeDropDown = (e) => {
  e.stopPropagation();
  document.querySelector('.dropdown').classList = 'dropdown is-right';
}

/**
 * @description Handles the click of a modal item
 */
export const showModal = () => {
  document.querySelector('body').classList.add('block-scroll');
  document.querySelectorAll('.modal').forEach((modal) => modal.classList.add('is-active'));
}

/**
 * @description Triggers the download of a file
 */
export const downloadFile = (file) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

/**
 * @description Updates the active item according to current step
 * @param {int} step the current step
 */
export const updateStep = (step) => {
  document.querySelectorAll('.is-completed').forEach(e => e.classList.remove('is-completed'));
  document.querySelectorAll('.step-item').forEach( (e,i) => {
    if (i < step) {
      e.classList.add('is-completed');
    }
  });
  document.querySelectorAll('.is-active').forEach(e => e.classList.remove('is-active'));
  document.querySelectorAll('.step-item')[step-1].classList.add('is-active');
  document.querySelectorAll('.step-content.has-text-centered')[step-1].classList.add('is-active');
}

/**
 * @description Returns the max step according to the current values
 * @param {dictionary} values 
 * @returns {int} the max step
 */
export const getMaxStep = (values) => {
  if (values.app === '' || (values.app === SHUFFLEAPP && values.inStyle === '')) {
    return 1;
  } else if (values.code === '') {
    return 2;
  } else if ( 
       (values.app === NUMBERSAPP && (values.nums === '' || values.min === '' || values.max === ''))
    || (values.app === SHUFFLEAPP && ((values.inStyle === KEYBOARD && values.rawInput === '') || (values.inStyle === FILE && (values.inputFile === undefined && values.rawInput === ''))))) {
    return 3;
  } else if (values.output === '') {
    return 4;
  } else {
    return 5;
  }
}

const acceptedParams = {
  numbers: ['nums', 'min', 'max', 'output', 'code'],
  shuffle: ['rawInput', 'output', 'code', 'inStyle', 'inFilename', 'outFilename'],
  picker: ['rawInput', 'output', 'code', 'inStyle', 'inFilename', 'chosen'],
  bingo: ['output', 'codes']
}

export const initialValues = {
  app:'', nums: '', min: '', max: '', output: '', code: '',
  rawInput: '', inputFile: undefined, outputFile: undefined, inStyle: '',  inFilename: '', outFilename: '',
}

/**
 * @description Obtains a dictionary of params from 'get' params from the url
 * @returns {dictionary} the params
 */
export const getParamsFromUrl = (params) => {
  const values = {...initialValues};
  const app = params.get('app');
  values.app = app === null ? '' : app; 

  if (!Object.keys(acceptedParams).includes(app))
    return values;

  const keys = acceptedParams[app];
  for (let i = 0; i < keys.length; i++) {
    const key = acceptedParams[app][i];
    values[key] = params.get(key) || '';
  }
  if ((app === NUMBERSAPP || app === BINGOAPP) && values.output.length > 1)
    values.output = values.output.replaceAll(',',' - ');
  return values;
}

export const buildUrl = (app, params) => {
  const admittedKeys = acceptedParams[app]
  let urlCandidate = `${settings.serverURI}/apps/fast-verification?app=${app}`
  for (let i=0; i < admittedKeys.length; i++) {
    let key = admittedKeys[i];
    if (params[key]) {
      urlCandidate += `&${key}=${params[key]}`;
    }
  }
  return urlCandidate;
}