import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalTranslationsContext } from '../Contexts';
import MainBreadcrumb from '../../components/MainBreadcrumb';
import ProjectsBreadcrumb from './Breadcrumb';

// Pictures
import Main from '../../assets/images/league/team.png';
import Logo from '../../assets/images/league/logo.png';
import WholeLeague from '../../assets/images/league/whole-league.png';
import Map from '../../assets/images/league/map.png';

// Members
import LavaRand from '../../assets/images/league/lavarand.png';
import URand from '../../assets/images/league/urand.png';
import SeismicGirl from '../../assets/images/league/seismic-girl.png';
import ChaChaRand from '../../assets/images/league/chacharand.png';
import InterplanetaryGirl from '../../assets/images/league/interplanetary-girl.png';

const LeagueOfEntropy = () => {

  const [ gt ] = useContext(GlobalTranslationsContext);
  const [ t ] = useTranslation('projects');

  const breadCrumbsList = [
    <ProjectsBreadcrumb t={gt} />,
    <div className='breadcrumb-current'>
      <span>{ t('league.title') }</span>
    </div>
  ]

  return (
    <div className='body-center container'>
      <div className='box-content projects-entry increased-padding'>
        <MainBreadcrumb breadCrumbsList={breadCrumbsList} />
        <div>
          <h1 className='drand-title'>{ t('league.title') }</h1>
          <h2 className='drand-subtitle'>{ t('league.subtitle') }</h2>
          <hr />
          <div className='drand-content'>
            <img src={Main} alt='League of Entropy' />
            <p>
              Hoy en día, los <i>Faros de Aleatoriedad</i> generan números aleatorios en forma pública, 
              para diversos ámbitos de la vida que pueden afectar a millones de personas. Y si 
              bien existen varios faros en el mundo, entre ellos el <b>Faro de Aleatoriedad de 
              Random UChile</b>, entre más existan alrededor del mundo, más se podrá aumentar la 
              confianza de los usuarios en utilizar estos servicios de manera más segura. 
              Es así como, Random UChile y otras organizaciones alrededor del mundo han unido 
              fuerzas para proveer a los usuarios un <i>Faro de Aleatoriedad Distribuido</i>. Al fin y 
              al cabo, ocho faros distribuidos globalmente son mejores y mas confiables que uno solo.
            </p>
            <p>
              Estamos felices de presentar… <b>¡LA LIGA DE LA ENTROPÍA!</b>
            </p>
            <div className='drand-logo'>
              <img src={Logo} alt='DRAND Logo' />
            </div>

            <h2>¿Qué es un Faro de Aleatoriedad Distribuido?</h2>
            <p>
              Un Faro de Aleatoriedad es un servicio pública que provee números aleatorios impredecibles durante intervalos regulares.
            </p>
            <p>
              <b>drand</b> (pronunciado di-rand) es un faro de aleatoriedad distribuido desarrollado 
              por Nicolas Gailly; con la ayuda de Philipp Jovanovic y Mathilde Raynal. El proyecto drand 
              nace del artículo de investigación 
              <a href='https://www.ieee-security.org/TC/SP2017/papers/413.pdf' target='_blank' rel='noreferrer'>
                <i> Scalable Bias-Resitant Distributed Randomness</i>
              </a>.
            </p>
            <p>
              drand posee las siguientes propiedades:
            </p>
            <ul>
              <li>Disponible: el faro está disponible todo el tiempo.</li>
              <li>Impredecible: los valores futuros del faro no pueden ser calculados por adelantado.</li>
              <li>Sin sesgo: el resultado entregado por el faro esta uniformemente distribuido de manera aleatoria.</li>
              <li>Verificable: un observador externo no involucrado en la generación de los valores puede verificar la correcta operación del faro.</li>
              <li>Distribuido: aun si algunos servidores estén desconectados, el faro continua produciendo nuevos números utilizando los servidores disponibles restantes.</li>
            </ul>
            
            <p>
              La Liga de la Entropía corresponde a un conjunto de servidores alrededor del mundo que corren 
              una instancia del protocolo drand. Los nodos participantes contribuyen con sus fuentes individuales 
              de alta entropía para generar números aleatorios e impredecibles, que son públicamente verificables, 
              una vez por minuto. ¿Quién está corriendo estos nodos?
            </p>
            <p>
              Actualmente, la Liga de la Entropía es un consorcio de distintas organizaciones alrededor del mundo 
              y contribuidores individuales, incluyendo: Universidad de Chile, Protocol Labs, Cloudflare, EPFL, 
              Kudelski Security, Nicolas Gailly, y los investigadores de EPFL, Phillip Jovanovic y Ludovic Barman.
            </p>

            <h2>La Liga de la Entropía</h2>

            <div className='drand-member has-text-right'>
              <img src={LavaRand} alt='LavaRand' className='is-pulled-right' />
              <h3>Cloudflare's LavaRand</h3>
              <p>
                LavaRand extrae su alta entropía de la muralla de lámparas de lava que existe en las oficinas centrales 
                de Cloudflare en San Francisco. El impredecible flujo de la lava dentro de las lámparas es utilizado, a 
                través de un video capturado por una cámara, como input de un CSPRNG (generador criptográficamente seguro 
                de números pseudo aleatorios) que genera el valor aleatorio.
              </p>
            </div>

            <div className='drand-member has-text-left'>
              <img src={URand} alt='URand' className='is-pulled-left' />
              <h3>EPFL's URand</h3>
              <p>
              El poder de URand viene de un generador local de aleatoriedad presente en cada computador a través del 
              dispositivo /dev/urandom. El input de dicha aleatoriedad es recolectado de distintas fuentes, como las 
              pulsaciones en el teclado, los clicks del mouse, tráfico de red, entre otros. URand agrupa esos inputs 
              aleatorios para producir un flujo continuo de aleatoriedad.
              </p>
            </div>

            <div className='drand-member has-text-right'>
              <img src={SeismicGirl} alt='Seismic Girl' className='is-pulled-right' />
              <h3>Random UChile's Seismic Girl</h3>
              <p>
                Seismic Girl extrae aleatoriedad super verificable de 5 fuentes consultadas cada minuto. Estas fuentes 
                son: mediciones de sismos y terremotos en Chile, la transmisión online de una radio local, una selección 
                de tweets (Twitter), datos obtenidos desde la blockchain de Ethereum, y su propia tarjeta TRNG (generador 
                de números realmente aleatorios).
              </p>
            </div>

            <div className='drand-member has-text-left'>
              <img src={ChaChaRand} alt='ChaCha Rand' className='is-pulled-left' />
              <h3>Kudelski Security's ChaChaRand</h3>
              <p>
                ChaChaRand utiliza una CRNG (generador criptográfico de números aleatorios) basado en el cifrador de flujo ChaCha20. 
              </p>
            </div>

            <div className='drand-member has-text-right'>
              <img src={InterplanetaryGirl} alt='Interplanetary Girl' className='is-pulled-right' />
              <h3>Protocol Labs' Interplanetary Girl</h3>
              <p>
              Interplanetary Girl utiliza el poder de la entropía para asegurar la seguridad a través del espacio-tiempo, usando 
              ruido ambiental y el PRNG de Linux, complementado por aleatoriedad directamente extraída de la CPU (RdRand).
              </p>
            </div>

            <br/>

            <p>
              Juntos, estos héroes combinan su aleatoriedad para formar un faro de aleatoriedad globalmente distribuido 
              y criptográficamente verificable, y están #SalvandoLaInternet (<i>#SavingTheInternet</i>) al proveer 
              aleatoriedad pública.
            </p>
            <div className='whole-league-img'>
              <img src={WholeLeague} alt='Whole League of Entropy' />
            </div>
            
            <h2>Quiero Aleatoriedad</h2>
            <p>
              Para conocer más sobre la Liga de la Entropía y como usar el Faro de Aleatoriedad distribuido, 
              visita <a href='https://leagueofentropy.com/' target='_blank' rel='noreferrer'>leagueofentropy.com</a>. 
              El sitio muestra periódicamente la aleatoriedad generada por la red, e incluso puedes observar los valores 
              generados anteriormente.
            </p>
            <div className='whole-league-img'>
              <img src={Map} alt='World Map' />
            </div>

            <h2>Como unirse a la Liga</h2>
            <p>
              ¿Quiéres unirte a la liga? ¡No somos exclusivos!
            </p>
            <p>
              Si perteneces a una organización que está interesada en contribuir al proyecto <i>drand</i>, revisa la {' '}
              <a href='https://developers.cloudflare.com/randomness-beacon/' target='_blank' rel='noreferrer'>
               documentación para desarrolladores
              </a> para más información sobre los requisitos para poder montar un servidor y unirse al grupo existente.{' '} 
              <i>drand</i> está actualmente en fase beta de desarrollo y el envío de una solicitud es necesaria para 
              poder ser aprobado como un nuevo servidor dentro de la red. Las solicitudes deben ser enviadas a {' '} 
              <a href='mailto:leagueofentropy@googlegroups.com' target='_blank' rel='noreferrer'>leagueofentropy@googlegroups.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeagueOfEntropy