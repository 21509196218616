import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';

// Utils and Contexts
import { dateToHtml, localDateToHtml } from '../../../utils/viewer';
import { LocalTranslationsContext } from '../../Contexts';
import { getExternalValues } from '../../../utils/beacon';

function getStatusMessages(code) {
	let messages = [];
	if (code === 0) {
		return ['ok'];
	}
	let exponents = Array.from(Array(4).keys())
	exponents.forEach((exp) => {
		if (code & Math.pow(2, exp)) {
			messages.push(`bit-${exp}`);
		}
	});
	if (messages.length === 0 || code > 15) {
		messages = [ 'unknown' ];
	}
	return messages;
}

function getExternalStatusMessages(code) {
	switch (code) {
		case 0:
			return [ 'not-used' ]
		case 3:
			return [ 'ok' ]
		default:
			let messages = [];
			let exponents = Array.from(Array(4).keys())
			exponents.forEach((exp) => {
				if (code & Math.pow(2, exp)) {
					messages.push(`bit-${exp}`);
				}
			});
			if (messages.length === 0 || code > 15)
				messages = [ 'unknown' ];
			return messages;
	}
}

export default function PulseDetail({pulse}) {

	const [ t ] = useContext(LocalTranslationsContext);
	const [ extData, setExtData ] = useState({});
  
	useEffect(() => {
		setTimeout(() => {
			getExternalValues(pulse.chainIndex, pulse.pulseIndex).then(data => {
				if (Object.keys(data).length > 2)
					setExtData(data);
			});
		}, 500);
	}, [pulse]);

  return (
    <div>
			<h2 className='pulse-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.chain') } #{pulse.chainIndex}</h2>
			<h2 className='pulse-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.pulse') } #{pulse.pulseIndex}</h2>
			<table className='pulse-table'>
				<tbody>
					<tr>
						<td className='pulse-item-title'>Version</td>
						<td className='pulse-item'>{pulse.version}</td>
					</tr>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.frequency') }</td>
						<td className='pulse-item'>{pulse.period / 1000} { t('sections.tools.viewer.advanced.pulse-viewer.seconds') }</td>
					</tr>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.timestamp') }</td>
						<td className='pulse-item'>
							{ localDateToHtml(pulse.timeStamp)}<br/> 
							{ dateToHtml(pulse.timeStamp)}
						</td>
					</tr>
				</tbody>
			</table>
			<h3 className='has-text-centered'>{ t('sections.tools.viewer.advanced.pulse-viewer.previous-values') }</h3>
			<hr className='pulse-section-divisor' />
			<table className='pulse-table'>
				<tbody>
					<tr className='pulse-row'>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.previous') }</td>
						<td className='pulse-item'>{ pulse.listValues[0].value }</td>
					</tr>
					<tr className='pulse-row'>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.hour') }</td>
						<td className='pulse-item'>{ pulse.listValues[1].value }</td>
					</tr>
					<tr className='pulse-row'>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.day') }</td>
						<td className='pulse-item'>{ pulse.listValues[2].value }</td>
					</tr>
					<tr className='pulse-row'>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.month') }</td>
						<td className='pulse-item'>{ pulse.listValues[3].value }</td>
					</tr>
					<tr className='pulse-row'>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.year') }</td>
						<td className='pulse-item'>{ pulse.listValues[4].value }</td>
					</tr>
				</tbody>
			</table>
			<h3 className='has-text-centered'>{ t('sections.tools.viewer.advanced.pulse-viewer.local-values') }</h3>
			<hr className='pulse-section-divisor' />
			<table className='pulse-table'>
				<tbody>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.local') }</td>
						<td className='pulse-item'>{ pulse.localRandomValue }</td>
					</tr>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.precommitment') }</td>
						<td className='pulse-item'>{ pulse.precommitmentValue }</td>
					</tr>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.signature') }</td>
						<td className='pulse-item'>{ pulse.signatureValue }</td>
					</tr>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.status') }</td>
						<td className='pulse-item'>
							{ 
								getStatusMessages(pulse.statusCode).map((message, index) => (
									<span key={index}>{ t(`sections.tools.viewer.statuses.${message}`) }. </span>
								)) 
							} ({ pulse.statusCode })
						</td>
					</tr>
				</tbody>
			</table>
			<h3 className='has-text-centered'>{ t('sections.tools.viewer.advanced.pulse-viewer.external-values') }</h3>
			<hr className='pulse-section-divisor' />
			<table className='pulse-table'>
				<tbody>
          {
            extData && extData.sources &&
            extData.sources.map((source, index) => (
              <tr key={index}>
                <td className='pulse-item-title'>{ t(`sections.tools.viewer.advanced.pulse-viewer.${source.name}`) }</td>
                <td className='pulse-item'>{ source.digest }</td>
              </tr>
            ))
          }
					{
						extData && extData.vdfData && extData.vdfData.output &&
						<tr>
							<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.vdf') }</td>
							<td className='pulse-item'>{ extData.vdfData.output }</td>
						</tr>
					}
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.external-value') }</td>
						<td className='pulse-item'>{ pulse.external.value }</td>
					</tr>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.external-status') }</td>
						<td className='pulse-item'>
							{ 
								getExternalStatusMessages(pulse.external.statusCode).map((message, index) => (
									<span key={index}>{ t(`sections.tools.viewer.external-statuses.${message}`) }. </span>
								)) 
							} ({pulse.external.statusCode})
						</td>
					</tr>
				</tbody>
			</table>
			<h3 className='has-text-centered'>{ t('sections.tools.viewer.advanced.pulse-viewer.pulse-value') }</h3>
			<hr className='pulse-section-divisor' />
			<table className='pulse-table'>
				<tbody>
					<tr>
						<td className='pulse-item-title'>{ t('sections.tools.viewer.advanced.pulse-viewer.output') }</td>
						<td className='pulse-item'>{ pulse.outputValue }</td>
					</tr>
				</tbody>
			</table>
			<a href={pulse.uri} className='button is-link is-light full-width' target='_blank' rel='noreferrer'>
				{ t('sections.tools.viewer.advanced.shortcut-buttons.complete-pulse') }
				<FontAwesomeIcon className='spaced-fa-icon' icon={faArrowUpRightFromSquare} />
			</a>
		</div>
  )
}

PulseDetail.propTypes = {
  pulse: PropTypes.object.isRequired
}