export const getNewFile = (content, fileName, type, code) => {
  const extLocation = fileName.lastIndexOf('.')
  const extension = fileName.slice(extLocation)
  const newFileName = fileName.slice(0, extLocation) + `_shuffled_${code}` + extension;

  // TODO: handle XLSX files
  return new File([content.join('\n')], newFileName, {
    type: type,
  });
}

export const rebuildFile = (filename, content) => {
  return new File([content.join('\n')], filename, {
    type: 'plain/text',
  });
}