import { faArrowPointer, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { toggleAccordion } from '../../utils/utils';
import { LocalTranslationsContext } from '../Contexts';

const VerifiabilityAccordion = () => {

  const [ t ] = useContext(LocalTranslationsContext);

  return (
    <section className='accordions' id='accordions'>
      <article className='accordion' id='how-to-verify' onClick={toggleAccordion}>
        <div className='accordion-header'>
          <p>
            { t('verify.title') }
            <FontAwesomeIcon className='spaced-fa-icon' icon={faArrowPointer} />
          </p>
        </div>
        <div className='accordion-body'>
          <div className='accordion-content'>
            <p>{ t('verify.qr') }</p>
          </div>
          <div className='accordion-buttons'>
            <Link to='/apps/verification'>
              <button className='button is-link' style={styles.verificationButton}>
                <FontAwesomeIcon icon={faCircleQuestion} className='spaced-fa-icon' />
                <span>{ t('verify.read-more') }</span>
              </button>
            </Link>
            {/* <Link to='/apps/verify'>
              <button className='button is-link' style={styles.verificationButton}>
                <FontAwesomeIcon icon={faCircleCheck} className='spaced-fa-icon' />
                <span>{ t('apps.verify.link-button') }</span>
              </button>
            </Link> */}
          </div>
        </div>
      </article>
    </section>
  )
}

const styles = {
  verificationButton: {
    marginBottom: '0.75rem',
    marginTop: '-0.5rem'
  },
  verificationCode: {
    backgroundColor: 'gray',
    borderRadius: '3px',
    color: 'white',
    padding: '1px 5px 1px 5px',
  }
}

export default VerifiabilityAccordion