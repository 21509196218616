import React, { useContext } from 'react';
import { GlobalTranslationsContext } from '../../pages/Contexts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faBullseye } from '@fortawesome/free-solid-svg-icons';

import esFlag from '../../assets/images/langs/es.png';
import enFlag from '../../assets/images/langs/en.png';

const NavBarSocial = () => {

  const [ , i18n ] = useContext(GlobalTranslationsContext);

  const storedLang = localStorage.getItem('lang');

  const toggleLanguage = () => {

    switch (storedLang) {
      case 'es':
        localStorage.setItem('lang', 'en');
        i18n.changeLanguage('en');
        return

      case 'en':
        localStorage.setItem('lang', 'es');
        i18n.changeLanguage('es');
        return

      default:
        localStorage.setItem('lang', 'es');
        i18n.changeLanguage('es');
        return
    }
  }

  return (
    <div className='navbar-end social-items'>
      <div className="field is-grouped">
        <a className='external-icon' href='https://www.twitter.com/randomuchile' target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faTwitter} />  
        </a>
        <a className='external-icon' href='mailto:random@uchile.cl' target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faEnvelope} />  
        </a>
        <a className='external-icon' href='https://randomuchile.statuspage.io' target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faBullseye} />  
        </a>
        <p className='external-icon lang-toggle' onClick={toggleLanguage}>
          <img id='lang-img' alt='Lang Selector' src={storedLang === 'es' ? enFlag : esFlag} />
        </p>
      </div>
    </div>
  )
}

export default NavBarSocial