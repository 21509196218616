import React from 'react';

import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PickerOutput = ({values, t, tg, choose}) => {

  const elementsToShow = Math.min(values.outputList.length, choose)

  return (
    <div className='has-text-centered'>
      <label>
        <b> {choose} { t('apps.picker.of') } { values.outputList.length } { t('apps.picker.elements') } </b>
        { t('apps.picker.elected') }.
      </label>
      <div className='shuffled-container keyboard-output' id='shuffled-result'>
        {
          elementsToShow > 1 ?
            <ol>
              { values.outputList.map((row, index) => <li key={index}>{row}</li>).slice(0, elementsToShow) }
            </ol>
            :
            <p>{ values.outputList[0] }</p>
        }
      </div>
      <div className='code-container-light' id='code-container'>
        <span id='verification-code' className='verification-code'>
          { tg('home.prng.code-msg') }: <b>{ values.code }</b>
        </span>
        <div className='result-tooltip'>
          <FontAwesomeIcon icon={faCircleQuestion} />
          <span className='tooltiptext'>{ tg('home.prng.tooltip') }</span>
        </div>
      </div>
    </div>
  )
}

export default PickerOutput