import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../Breadcrumb';
import MainBreadcrumb from '../../../components/MainBreadcrumb';
import PulseCountdown from '../../../components/PulseCountdown';

import ShuffleApp from './../Shuffle/ShuffleApp';

const RandomPicker = () => {

  const [ t ] = useTranslation('apps');

  const breadCrumbsList = [
    <Breadcrumb t={t} />,
    <div className='breadcrumb-current'>
      <span>{t('apps.picker.title')}</span>
    </div>
  ]

  return (
    <div className='container app-page'>
      <div className='app-container'>
        <div className='breadcrumbs'>
          <MainBreadcrumb breadCrumbsList={breadCrumbsList} />
        </div>
        <div className='app-content'>
          <div className='application shuffle-app'>
            <ShuffleApp randomPicker={ true } />
          </div>
          <div className='pulse-countdown'>
            <PulseCountdown colorTheme='full-height' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RandomPicker