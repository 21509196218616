import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import PulseDetail from './PulseDetail';
import BeaconBreadcrumb from '../Breadcrumb';
import BreadcrumbsPageWrapper from '../../../components/BreadcrumbsPageWrapper';

// Utils
import { getPulse, getPulseByTimeStamp, getLastPulse } from '../../../utils/beacon';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBackwardFast,
  faBackwardStep,
  faCalendar,
  faForwardFast,
  faForwardStep,
  faMagnifyingGlass,
  faHashtag,
  faWarning,
  faWaveSquare,
} from '@fortawesome/free-solid-svg-icons';

// Bulma
import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min';
import '../../../../node_modules/bulma-calendar/dist/css/bulma-calendar.min.css';
import { BeaconContext } from '../../Contexts';


function getUTCTimestamp(datetime) {
  const splitted = datetime.split(' ');
  const dateLst = splitted[0].split('/');
  const timeLst = splitted[1].split(':');
  const dateUTC = new Date(dateLst[2], parseInt(dateLst[1])-1, dateLst[0], timeLst[0], timeLst[1]);
  return dateUTC.toISOString();
}

function getDateAndTime(timestamp) {
  let utcDate = new Date(timestamp);
  let localDateStr = utcDate.toLocaleString('es-CL', { hour12: false }).split(', ')
  
  let dateStr = localDateStr[0].replaceAll('-','/')
  let timeStr = localDateStr[1].substring(0, 5)

  return { date: dateStr, time: timeStr }
}

export default function ViewerAdvanced() {
  const [ t ] = useTranslation('beacon');
  const [ searcher, setSearcher ] = useState( 'timestamp' );

  const breadCrumbsList = [
    <BeaconBreadcrumb t={t} />,
    <Link to='/randomness-beacon/advanced-viewer'>
      <span>{ t('sections.tools.viewer.advanced.title') }</span>
    </Link>,
  ]

  // PulseGetter
  const [ targetTimestamp, setTargetTimestamp ] = useState(new Date().toISOString());
  const [ targetChainId, setTargetChainId ] = useState(1);
  const [ targetPulseId, setTargetPulseId ] = useState(1);

  const [ loading, setLoading ] = useState( true );
  const [ pulseError, setPulseError ] = useState( false );
  const [ errorCode, setErrorCode ] = useState( 200 );
  const [ searchParams ] = useSearchParams();
  const [ pulse, setPulse ] = useState({});

  useEffect(() => {
    if (pulse){
      if (Object.keys(pulse).length === 2) {
        setPulseError(true);
        let errorType = Math.floor(pulse.status/100)*100;
        setErrorCode(errorType);
        setLoading(false);
      } else if (Object.keys(pulse).length > 2) {
        setTargetChainId(pulse.chainIndex);
        setTargetPulseId(pulse.pulseIndex);
        setTargetTimestamp(pulse.timeStamp);
        setLoading(false);
      }
    }
  }, [pulse]);

  const pulseByIndex = useCallback((chainId, pulseId) => {
    setTargetChainId(chainId);
    setTargetPulseId(pulseId);
    getPulse(chainId, pulseId)
      .then(setPulse)
  }, [setPulse]);

  const pulseByTimestamp = useCallback((timestamp) => {
    setTargetTimestamp(timestamp);
    getPulseByTimeStamp(timestamp)
      .then(setPulse)
  }, [setPulse]);

  const lastPulse = useCallback(() => {
    getLastPulse()
      .then(setPulse)
  }, [setPulse]);

  const initViewer = useCallback(() => {
    let chainId, pulseId, timestamp;
    chainId = searchParams.get('chainId');
    pulseId = searchParams.get('pulseId');
    timestamp = searchParams.get('timestamp');

    if (chainId !== null && pulseId !== null) {
      setSearcher('id');
      chainId = parseInt(chainId);
      pulseId = parseInt(pulseId);
      pulseByIndex(chainId, pulseId);
    } else if (timestamp !== null) {
      setSearcher('timestamp');
      pulseByTimestamp(timestamp);
    } else {
      lastPulse();
    }
  }, [lastPulse, pulseByTimestamp, pulseByIndex, searchParams]);

  useEffect(() => {
    initViewer();
  }, [initViewer]);

  // This is used to change the search tool
  useEffect( () => {
    // If we want to search a pulse by ID, then we show this inputs
    if (searcher === 'id') {
      document.querySelector('#adv-viewer-tabs').childNodes[1].classList.remove('is-active');
      document.querySelector('#adv-viewer-tabs').childNodes[0].classList.add('is-active');
      document.getElementById('timestamp-input').classList.add('is-hidden');
      document.getElementById('pulse-input').classList.remove('is-hidden');
      document.getElementById('chain-input').classList.remove('is-hidden');
    // Else, we search by date (we could add more cases)
    } else {
      document.querySelector('#adv-viewer-tabs').childNodes[0].classList.remove('is-active');
      document.querySelector('#adv-viewer-tabs').childNodes[1].classList.add('is-active');
      document.getElementById('timestamp-input').classList.remove('is-hidden');
      document.getElementById('pulse-input').classList.add('is-hidden');
      document.getElementById('chain-input').classList.add('is-hidden');
    }
  }, [searcher]);

  const searchTargetPulse = () => {
    setPulseError(false);
    setLoading(true);
    switch (searcher) {
      case 'id':
        getPulse(targetChainId, targetPulseId)
          .then(setPulse)
        break

      case 'timestamp':
        getPulseByTimeStamp(targetTimestamp)
          .then(setPulse)
        break
      
      default:
        console.error('No searcher was specified')
    }
  } 

  return (
    <BreadcrumbsPageWrapper t={t} customClass={'viewer-page'} breadcrumbs={breadCrumbsList}>
      <Link to='/randomness-beacon/viewer'>
        <span className='button is-success is-rounded verify-button'>
          <FontAwesomeIcon icon={faWaveSquare} />
          <span>{ t('sections.tools.viewer.simple.link-button') }</span>
        </span>
      </Link>
      <div className='light-bordered advanced-viewer-page'>
        <h1>{ t('sections.tools.viewer.advanced.title') }</h1>

        <SearchTabs 
          t={t}
          searchById={(e) => {
            e.preventDefault();
            setSearcher('id');
          }}
          searchByTimestamp={(e) => {
            e.preventDefault();
            setSearcher('timestamp');
          }}
        />

        { pulseError &&
          <div className='box has-text-centered has-background-danger has-text-white'>
            <FontAwesomeIcon icon={faWarning} /> {t(`sections.tools.viewer.advanced.error${errorCode}`)}
          </div>
        }

        {/* { loading ?
          <p>Cargando ...</p>
          :
          <>
            <p>Target Timestamp: { targetTimestamp }</p>
            <p>Target Chain: { targetChainId }</p>
            <p>Target Pulse: { targetPulseId }</p>
          </>
        } */}
        <div className='columns'>
          
          <SearchForm
            t={t}
            targetChainId={targetChainId}
            setTargetChainId={setTargetChainId}
            targetPulseId={targetPulseId}
            setTargetPulseId={setTargetPulseId}
            targetTimestamp={targetTimestamp}
            setTargetTimestamp={setTargetTimestamp}
          />
          <div className='field column has-text-centered'>
            <button 
              onClick={searchTargetPulse}
              className='button is-link viewer-search-button'
              id='generator-button'
              disabled={loading}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div> 
        </div>
            
        <PulseQuickSearchButtons 
          t={t} 
          chainId={targetChainId}
          pulseId={targetPulseId}
          setPulse={setPulse} 
          loading={loading}
          setLoading={setLoading}
          pulseError={pulseError}
          setPulseError={setPulseError}
        />

        <hr />

        { (!loading && !pulseError) && <PulseDetail pulse={pulse} /> }
      </div>
    </BreadcrumbsPageWrapper>
  )
}

function SearchTabs({t, searchById, searchByTimestamp}) {
  return (
    <div className='tabs is-centered'>
      <ul className='input-shuffle' id='adv-viewer-tabs'>
        <li>
          <a href='/' onClick={searchById}>
            <FontAwesomeIcon icon={ faHashtag } className='searcher-icon' />
            { t('sections.tools.viewer.advanced.by-id') }
          </a>
        </li>
        <li className='is-active'>
          <a href='/' onClick={searchByTimestamp}>
            <FontAwesomeIcon icon={ faCalendar } className='searcher-icon' />
            { t('sections.tools.viewer.advanced.by-timestamp') }
          </a>
        </li>
      </ul>
    </div>
  )
}

function SearchForm({t, targetChainId, setTargetChainId, targetPulseId, setTargetPulseId, targetTimestamp, setTargetTimestamp}) {
  const attachCalendarInput = useCallback( () => {
    let { date, time } = getDateAndTime(targetTimestamp);
    // Thanks to: https://gist.github.com/silkyfray/d46babf96c792ef99d09e38ed0ca583a
    // TODO: update when changing by id
    bulmaCalendar.attach("[type='datetime']", {
      color: 'link',
      dateFormat: 'dd/MM/yyyy',
      lang: localStorage.getItem('lang') === 'es' ?  'es' : 'en-US',
      maxDate: new Date(),
      startDate: date,
      weekStart: 1,
      minuteSteps: 1,
      startTime: time,
    });
  }, [targetTimestamp])

  useEffect(() => {
    attachCalendarInput();
    // To access to bulmaCalendar instance of an element
    const element = document.querySelector('#my-bulma-datetime');
    if (element) {
      element.bulmaCalendar.on('select', (dp) => {
        let utcTimestamp = getUTCTimestamp(dp.data.value());
        setTargetTimestamp(utcTimestamp);
      });
    }
  }, [attachCalendarInput, setTargetTimestamp]);

  return (
    <>
      <div className='field column has-text-centered is-four-fifths' id='timestamp-input'>
        <label className='label-input'>{ t('sections.tools.viewer.advanced.timestamp') }</label>
        <div className='control'>
          <input type='datetime' className='input' id='my-bulma-datetime' />
        </div>
      </div>
      <div className='field column has-text-centered is-two-fifths' id='chain-input'>
        <label className='label-input'>{ t('sections.tools.viewer.advanced.chain-id') }</label>
        <div className='control'>
          <input 
            className='input'
            type='number'
            value={targetChainId}
            onChange={(e) => setTargetChainId(parseInt(e.target.value))}
          />
        </div>
      </div>
      <div className='field column has-text-centered is-two-fifths' id='pulse-input'>
        <label className='label-input'>{ t('sections.tools.viewer.advanced.pulse-id') }</label>
        <div className='control'>
          <input 
            className='input'
            type='number'
            value={targetPulseId}
            onChange={(e) => setTargetPulseId(parseInt(e.target.value))}
          />
        </div>
      </div>
    </>
  )
}

function PulseQuickSearchButtons({t, chainId, pulseId, setPulse, loading, setLoading, pulseError, setPulseError}) {

  const { pulse:latestPulse } = useContext(BeaconContext);

  const getFirstPulse = () => {
    setLoading(true);
    setPulseError(false);
    getPulse(chainId, 1)
      .then(setPulse)
  }

  const getPreviousPulse = () => {
    setLoading(true);
    setPulseError(false);
    getPulse(chainId, pulseId - 1)
      .then(setPulse)
  }

  const getNextPulse = () => {
    setLoading(true);
    setPulseError(false);
    getPulse(chainId, pulseId + 1)
      .then(setPulse)
  }

  const getRecentPulse = () => {
    setLoading(true);
    setPulseError(false);
    getLastPulse()
      .then(setPulse)
  }

  return (
    <div className='columns'>
      <QuickSearchButton
        text={t('sections.tools.viewer.advanced.shortcut-buttons.first')}
        icon={faBackwardFast}
        onClick={getFirstPulse}
        disabled={loading}
      />
      <QuickSearchButton
        text={t('sections.tools.viewer.advanced.shortcut-buttons.previous')}
        icon={faBackwardStep}
        onClick={getPreviousPulse}
        disabled={loading || pulseError || pulseId === 1}
      />
      <QuickSearchButton
        text={t('sections.tools.viewer.advanced.shortcut-buttons.next')}
        icon={faForwardStep}
        onClick={getNextPulse}
        disabled={loading || pulseError || (latestPulse.pulseIndex === pulseId && latestPulse.chainIndex === chainId)}
      />
      <QuickSearchButton
        text={t('sections.tools.viewer.advanced.shortcut-buttons.last')}
        icon={faForwardFast}
        onClick={getRecentPulse}
        disabled={loading}
      />
    </div>
  )
}

function QuickSearchButton({text, icon, onClick, disabled=false}) {
  return (
    <div className='field column has-text-centered'>
      <button onClick={onClick} className='button is-link is-light full-width' id='generator-button' disabled={disabled}>
        <FontAwesomeIcon className='spaced-fa-icon' icon={icon} />
        { text }
      </button>
    </div>
  )
}
