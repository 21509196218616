import { getPulse } from "./beacon";
import { BINGOAPP, NUMBERSAPP, SHUFFLEAPP, RANDPICKERAPP } from "./utils";

/**
 * Makes use of chacha to shuffle a list
 * @param {List} list 
 * @param {ChaCha} chacha 
 * @returns {List}
 */
export const shuffleList = ( list, chacha ) => {
  let elements = cleanList( list );

  const totalElements = elements.length;
  let aux1 = elements;
  let finalShuffle = [];
  let length = aux1.length;
  let selection = 0;

  for (let i = 0; i < totalElements; i++) {
    try {
      selection = chacha.randUInt( length - 1 );
    } catch (err) {
      selection = 0;
    }
    finalShuffle.push( aux1[selection] );
    aux1.splice( selection, 1 );
    length = length - 1;
  }
  return finalShuffle;
}

/**
 * Auxiliar Function: Trims elements and removes empty.
 * @param {List} raw_list 
 * @returns clean list
 */
const cleanList = raw_list => {
  const removedBlankSpaces = raw_list.map( x => x.trim() );
  return removedBlankSpaces.filter( n => n !== '' );
}

/**
 * Transforms a list into text with elements separated by dots.
 * @param {List} l 
 * @returns list as text
 */
export const splitListDots = (l) => {
  let txt = l.length > 0 ? `${l[0]}` : '';
  for (let i = 1; i < l.length; i++) {
    txt += ` - ${l[i]}`;
  }
  return txt;
}

/**
 * Auxiliar Function: Gets all numbers between min and max values
 * @param {int} minValue 
 * @param {int} maxValue 
 * @returns the list
 */
export const getUniverse = ( minValue, maxValue ) => {
  return Array.apply(null, {
      length: Math.ceil(maxValue - minValue + 1)
    }).map(
      Number.call, Number
    ).map((num) => (
      num + Math.ceil(minValue)
  ));
}

const getPulseAndSequence = async (code) => {
  const [ chainIndex, pulseIndex, trySequence ] = code.split('-');
  const { ChaChaRand } = require('./chacha/chacha20-generator');

  // Seeds chacha with the indicated pulse
  const { outputValue } = await getPulse( chainIndex, pulseIndex );
  const chacha = new ChaChaRand( outputValue );

  return [chacha, trySequence];
}

/**
 * Given an app and a code, recreates the app result
 * @param {string} app 
 * @param {string} code 
 * @param {string} input 
 * @returns the result
 */
export const recreateResult = async ( values ) => {
  let r = 0;
  switch (values.app) {

    case NUMBERSAPP:
      const [chachaN, trySequenceN] = await getPulseAndSequence(values.code);
      const universe = getUniverse( parseInt(values.min), parseInt(values.max) );
      for(let i = 0; i < parseInt(trySequenceN); i++) {
        r = chachaN.sample(universe, parseInt(values.nums));
      }
      break;

    case RANDPICKERAPP:
    case SHUFFLEAPP:
      const [chachaS, trySequenceS] = await getPulseAndSequence(values.code);
      const rawList = values.rawInput.split('\n');
      for(let i = 0; i < parseInt(trySequenceS); i++) {
        r = shuffleList(rawList, chachaS);
      }
      break;

    case BINGOAPP:
      let bingoUniverse = getUniverse( 1, 75 );
      r = [];
      for(const code of values.codes.split(',')) {
        const [chachaB, quantity] = await getPulseAndSequence(code);
        for (let i = 0; i < parseInt(quantity); i++) {
          const [ newNum ] = chachaB.sample(bingoUniverse, 1);
          r.push(newNum);
          bingoUniverse = bingoUniverse.filter(x => x !== newNum);
        }
      }
      break;

    default:
      console.warn('Error al verificar');
      break;
  }

  return r;
}

/**
 * Compares the equality between given result and actual result
 * @param {string} app 
 * @param {*} currentResult 
 * @param {string} expectedResult 
 * @returns {boolean} true | false
 */
export const compareResults = ( app, currentResult, expectedResult ) => {
  switch (app) {
          
    case NUMBERSAPP:
      const expectedList = expectedResult.split(' - ').map( n => parseInt(n))
      if (currentResult.length !== expectedList.length) {
        return false;
      }
      for (let i = 0; i < currentResult.length; i++) {
        if (currentResult[i] !== expectedList[i]) {
          console.warn(`${currentResult[i]} !== ${expectedList[i]}`);
          return false;
        }
      }
      return true;

    // eslint-disable-next-line no-fallthrough
    case RANDPICKERAPP:
    case SHUFFLEAPP:
      const expectedResultList = expectedResult.split('\n');
      if (currentResult.length !== expectedResultList.length) {
        return false;
      }
      for (let i = 0; i < currentResult.length; i++) {
        if (currentResult[i].trim() !== expectedResultList[i].trim()) {
          return false;
        }
      }
      return true;

    case BINGOAPP:
      const expectedBingoList = expectedResult.split(' - ').map( n => parseInt(n));
      for(let i = 0; i < currentResult.length; i++) {
        if (currentResult[i] !== expectedBingoList[i]) {
          return false;
        }
      }
      return true;

    default:
      return false;
  }
}