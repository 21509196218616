import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToHtml } from '../../../utils/viewer';
import { BeaconContext } from '../../Contexts';
import PulseCountdown from '../../../components/PulseCountdown';
import BreadcrumbsPageWrapper from '../../../components/BreadcrumbsPageWrapper';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import BeaconBreadcrumb from '../Breadcrumb';

const ViewerSimple = () => {

  const [ t ] = useTranslation('beacon');
  const { pulse } = useContext(BeaconContext);

  const breadCrumbsList = [
    <BeaconBreadcrumb t={t} />,
    <Link to="/randomness-beacon/advanced-viewer">
      <span>{ t('sections.tools.viewer.simple.title') }</span>
    </Link>,
  ]

  return (
    <BreadcrumbsPageWrapper t={t} customClass={'viewer-page'} breadcrumbs={breadCrumbsList}>
      <Link to="/randomness-beacon/advanced-viewer">
        <span className='button is-success is-rounded verify-button'>
          <FontAwesomeIcon icon={faWaveSquare} />
          <span>{ t('sections.tools.viewer.advanced.link-button') }</span>
        </span>
      </Link>
      <div className='light-bordered simple-viewer-page'>
        <PulseCountdown colorTheme='light' pulseView={true} />
        <div className='simple-viewer-title'>
          <b>{ t('sections.tools.viewer.simple.subtitle') }</b>
          <br/>
          <i>{ dateToHtml(pulse.timeStamp) }</i>
        </div>
        <div className='simple-viewer'>
          { pulse.outputValue }
        </div>
      </div> 
    </BreadcrumbsPageWrapper>
  )
}

export default ViewerSimple