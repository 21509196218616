import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RandomUChile } from './RandomUChile';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';

import about_es from './translations/es/about.json';
import about_en from './translations/en/about.json';
import apps_es from './translations/es/apps.json';
import apps_en from './translations/en/apps.json';
import beacon_es from './translations/es/beacon.json';
import beacon_en from './translations/en/beacon.json';
import projects_es from './translations/es/projects.json';
import projects_en from './translations/en/projects.json';
import people_es from './translations/es/people.json';
import people_en from './translations/en/people.json';
import using_es from './translations/es/using.json';
import using_en from './translations/en/using.json';

import './assets/css/styles.css';

const initLang = () => {
  const storedLang = localStorage.getItem('lang')
  if (!!storedLang) {
    return storedLang
  } else {
    localStorage.setItem('lang', 'es');
    return 'es'
  }
}

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: initLang(),
  resources: {
    es: {
      about: about_es,
      apps: apps_es,
      beacon: beacon_es,
      global: global_es,
      projects: projects_es,
      people: people_es,
      using: using_es
    },
    en: {
      about: about_en,
      apps: apps_en,
      beacon: beacon_en,
      global: global_en,
      projects: projects_en,
      people: people_en,
      using: using_en
    }
  }
})

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next} >
      <RandomUChile />
    </I18nextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
