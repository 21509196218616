import React from 'react';
import { Link } from 'react-router-dom';

import { faDice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Breadcrumb = ({t}) => {

  return (
    <Link to="/apps">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faDice} />  
      </span>
      <span>{t('title')}</span>
    </Link>
  )
}

export default Breadcrumb