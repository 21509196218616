import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../../Breadcrumb';
import MainBreadcrumb from '../../../../components/MainBreadcrumb';
import FastVerifyApp from './FastVerifyApp';

const FastVerify = () => {

	const [ t ] = useTranslation('apps');
  
	const breadCrumbsList = [
    <Breadcrumb t={t} />,
    <div className='breadcrumb-current'>
      <span>{t('apps.fast-verification.title')}</span>
    </div>
  ];

  return (
    <div className='container app-page'>
      <div className='app-container'>
        <div className='breadcrumbs'>
          <MainBreadcrumb breadCrumbsList={breadCrumbsList} />
        </div>
        <div className='app-content'>
          <div className='application-verify'>
            <FastVerifyApp />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FastVerify