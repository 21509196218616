import { faCircleCheck, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../utils/utils';

const FileVerificationModal = ({fileIn, fileOut, url}) => {

  const [ t ] = useTranslation('apps');

  const closeModal = () => {
    document.querySelector('body').classList.remove('block-scroll');
    document.querySelector('.modal').classList.remove('is-active');
  }

  const copyToClipboard = () => {
    const button = document.getElementById('copy-button');
    button.disabled = true
    navigator.clipboard.writeText(url);
    button.lastChild.textContent = t('fast-verification.copied');
    setTimeout(() => {
      button.lastChild.textContent = t('fast-verification.copy');
      button.disabled = false;
    }, 1000);
  }

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      const e = event || window.event;
      if (e.key === 'Escape') { // Escape key
        closeModal();
      }
    });
    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
      $close.addEventListener('click', () => {
        closeModal();
      });
    });
  }, [])

  return (
    <div className='modal modal-qr'>
      <div className='modal-background'></div>
      <div className='modal-content'>
        <div className='modal-box'>
          <div className='modal-content-qr'>
            <p>{ t('fast-verification.file-desc') }</p>
          </div>
          <div className='modal-buttons'>
            <button className='button is-light is-link app-button mt-3' onClick={() => {
              downloadFile(fileIn);
              downloadFile(fileOut);
            }}>
              <FontAwesomeIcon icon={faDownload} className='spaced-fa-icon'/>
              <p>{ t('fast-verification.download-files') }</p>
            </button>
            <button className='button is-light is-link app-button mt-3' id='copy-button' onClick={copyToClipboard}>
              <FontAwesomeIcon icon={faCopy} className='spaced-fa-icon'/>
              <p>{ t('fast-verification.copy') }</p>
            </button>
            <button id='verify-button' className='button is-link is-light app-button mt-3' onClick={() => window.open(url)}>
              <FontAwesomeIcon icon={faCircleCheck} className='spaced-fa-icon' />
              <span>{ t('fast-verification.verify') }</span>
            </button>
          </div>
        </div>
      </div>
      <button className='modal-close is-large' aria-label='close'></button>
    </div>
  )
}

export default FileVerificationModal