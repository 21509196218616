import React from 'react'

const NotFound = () => {

  return (
    <div className='body-center container'>
      <div className='box-content'>
        <h1>404</h1>
        <hr />
        <p className='has-text-centered'>Page Not Found</p>
      </div>
    </div>
  )
}

export default NotFound