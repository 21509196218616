import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import VerificationBox from '../VerificationBox';
import { getParamsFromUrl} from '../../../../utils/utils';
import ShowContent from './ShowContent';


const FastVerifyApp = () => {

  const [ t ] = useTranslation('apps');
  const [ searchParams ] = useSearchParams();
  const values = getParamsFromUrl(searchParams);

  return (
    <div className='verification-app-container'>
      <h1 className='title is-4'>
        { t('apps.fast-verification.title') }
      </h1>
      <hr />

      <div>
        <ShowContent values={values} />
        <div className='has-text-centered fast-verification-status'>
          <VerificationBox values={values} />
        </div>
      </div>

    </div>

  )
}

export default FastVerifyApp;