import React, { useContext } from 'react';
import FooterLogo from './FooterLogo';

import { GlobalTranslationsContext } from '../../pages/Contexts';

import logoCLCERT from '../../assets/images/clcert-white.png';
import logoNIST from '../../assets/images/nist.svg';
import logoUChile from '../../assets/images/uchile.svg';
import logoNicCL from '../../assets/images/nicchile.png';


const Footer = () => {

  const [ t ] = useContext(GlobalTranslationsContext);

  return (
    <footer className='footer'>
      <FooterLogo
        picture={logoCLCERT}
        reference={'https://clcert.cl'}
        alt={'Logo CLCERT'}
        padding={0}
        text={
          <>
            <p> { t('footer.clcert.project') } <b> CLCERT, Universidad de Chile </b></p>
            <p> { t('footer.clcert.address') } Of. 201D, Santiago, Chile. { t('footer.clcert.postal-code') }</p>
            <p> { t('footer.clcert.email') } </p>
          </>
        }
      />
      <FooterLogo
        picture={logoNIST}
        reference={'https://www.nist.gov'}
        alt={'Logo NIST'}
        padding={14}
        text={
          <p>
            <b>Random UChile</b> { t('footer.nist.preamble') } 
            <a href='https://www.nist.gov' target='_blank' rel='noreferrer'> <i>National Institute of Standards and Technology</i> </a>
            { t('footer.nist.end') }.
          </p>
        }
      />
      <FooterLogo
        picture={logoNicCL}
        reference={'https://www.nic.cl'}
        alt={'Logo nic'}
        padding={14}
        text={
          <>
            <p> <b>Random UChile</b> { t('footer.nic.preamble') } <b> { t('footer.nic.name') } </b></p>
            <p> { t('footer.nic.address') }</p>
          </>
        }
      />
      <FooterLogo
        picture={logoUChile}
        reference={'https://www.uchile.cl'}
        alt={'Logo Uchile'}
        padding={0}
        text={''}
      />        
    </footer>
  )
}

export default Footer;
