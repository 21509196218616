import React from 'react';
import loadingGif from '../assets/images/loading.gif';

const ProgressBar = ({ loading, percentage }) => {
  return (
    <div className='percent' style={{'--percent': percentage}}>
      <div className='dot'></div>
      <svg>
        <circle cx='50' cy='50' r='40' />
        <circle cx='50' cy='50' r='40' />
      </svg>
      <div className='time-left'>
        {
          loading ? 
            <img src={loadingGif} alt='loading...' className='loading'/>
            :
            <h2>{Math.trunc(60*percentage/100)}<span>s</span></h2>
        }
      </div>
    </div>
  )
}

export const MiniProgressBar = ({ loading, percentage }) => {
  return (
    <div className='mini-percent' style={{'--percent': percentage}}>
      <div className='mini-dot'></div>
      <svg>
        <circle cx='20' cy='20' r='15' />
        <circle cx='20' cy='20' r='15' />
      </svg>
      <div className='time-left'>
        {
          loading ? 
            <img src={loadingGif} alt='loading...' className='mini-loading'/>
            : ''
        }
      </div>
    </div>
  )
}

export default ProgressBar