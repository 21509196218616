import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BeaconContext, GlobalTranslationsContext } from '../Contexts';
import { validatePRNGInput } from '../../utils/validators';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faDiceD20, faQrcode, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faDice } from '@fortawesome/free-solid-svg-icons';

import loadingGif from '../../assets/images/loading.gif';
import { buildUrl, closeDropDown, NUMBERSAPP, MAX_VALUE, showModal, toggleDropDown } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { qrCode } from '../../utils/verification';
import SharingModal from '../Apps/SharingModal';

const RandomNumberApp = () => {

  const { chacha, pulse } = useContext(BeaconContext);
  const [ counter, setCounter ] = useState(1);
  const [ tg ] = useContext(GlobalTranslationsContext);
  const [ t ] = useTranslation('apps');

  const [ url, setUrl ] = useState('');
  const [ buttonIcon, setButtonIcon ] = useState(faDiceD20);

  const [ values, setValues ] = useState({
    code: '',
    error: '',
    loading: false,
    min: 1,
    max: 10,
    output: undefined
  });

  /**
   * @description Handles the submit of a form and generates the random number
   * @param e the Event 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const genButton = document.querySelector('#generator-button');
    setValues({ ...values, output: undefined, error: '', loading: true });

    const numMin = parseInt(values.min);
    const numMax = parseInt(values.max);

    if (isNaN(numMin) || isNaN(numMax)) 
      setValues({ ...values, output: undefined, error: t('apps.numbers.blank-error'), loading: false });
    else if (Math.abs(numMin) > MAX_VALUE || Math.abs(numMax) > MAX_VALUE)
      setValues({ ...values, output: undefined, error: t('apps.numbers.size-error'), loading: false });
    else if ( !validatePRNGInput(1, numMin, numMax))
      setValues({ ...values, output: undefined, error: t('apps.numbers.default-error'), loading: false });
    else {
      setCounter((counter) => counter + 1);
      genButton.disabled = true;

      const verificationCode = pulse.chainIndex !== undefined ? 
        `${pulse.chainIndex}-${pulse.pulseIndex}-${counter}` 
        : 'Pulse Unavailable, Reload!';

      setTimeout( () => {
        var r = chacha.randInt(Math.ceil(numMin), Math.ceil(numMax));
        setValues({ ...values, output: r, error: '', loading: false, code: verificationCode });
        setUrl( buildUrl(NUMBERSAPP, {...values, nums: 1, code: verificationCode, output: r} ) )
        genButton.disabled = false;
        genButton.childNodes[1].innerText = tg('home.prng.regenerate');
        setButtonIcon(faRotateRight);
      }, 500);
    }
  }

  const reseedAndReset = useCallback(() => {
    chacha.reseed(pulse.outputValue);
    setCounter(1);
  }, [chacha, pulse]);

  useEffect(() => {
    reseedAndReset(); // reseed whenever the input changes    
  }, [values.min, values.max, reseedAndReset]);

  useEffect(() => {
    const size = url.length * 0.8 > 250 ? url.length * 0.8 : 250;
    if (values.output !== undefined) {
      url.length <= 400 ?
        qrCode.update({ data: url, width: size, height: size}) : qrCode.update({ data: '' });
    }
  }, [url, values.output]);

  return (
    <div className='app-container-default mb30'>
      <h1 className='app-title'>
        { tg('home.prng.title') }
        <div className='dropdown is-right apps-shortcut' onClick={toggleDropDown} onMouseLeave={closeDropDown}>
          <div className='dropdown-trigger'>
            <button className='button' aria-haspopup='true' aria-controls='dropdown-menu2'>
              <span className='icon is-small'>
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </button>
          </div>
          <div className='dropdown-menu' id='dropdown-menu2' role='menu'>
            <div className='dropdown-content'>
              <Link to='/apps' className='dropdown-item'>
                <span className='apps-icon'>
                  <FontAwesomeIcon icon={faDice} />  
                </span>
                <span>{ tg('home.prng.others') }</span>
              </Link>
            </div>
          </div>
        </div>
      </h1>

      <div className='input-container'>
        <div className='field has-text-centered half-space'>
          <div className='control custom-input custom-input-default'>
            <label>{ tg('home.prng.min') }</label>
            <input className='input' type='number' value={values.min} maxLength='7' onChange={(e) => setValues({...values, min: e.target.value })}/>
          </div>
        </div>
        <div className='field has-text-centered half-space'>
          <div className='control custom-input custom-input-default'>
            <label>{ tg('home.prng.max') }</label>
            <input className='input' type='number' value={values.max} maxLength='7' onChange={(e) => setValues({...values, max: e.target.value })}/>
          </div>
        </div>
      </div>

      {
        values.loading &&
        <div className='result-box result-container-default' id='generator-result'>
          <img src={loadingGif} className='loading-icon' alt='...'/>
        </div>
      }
      {
        values.error !== '' &&
        <div className='result-box bad-input'>
          { values.error }
        </div>
      }
      {
        values.output !== undefined &&
        <>
          <div className='result-box result-container-default' id='generator-result'>
            { values.output }
          </div>
          <div className='code-container-default' id='code-container'>
            <span id='verification-code' className='verification-code'>{ tg('home.prng.code-msg') }: <b>{ values.code }</b></span>
            <div className='result-tooltip'>
              <FontAwesomeIcon icon={faCircleQuestion} />
              <span className='tooltiptext'>{ tg('home.prng.tooltip') }</span>
            </div>
          </div>
        </>
      }

      <div className='has-text-centered buttons-container'> 
        <button className='button is-link is-light app-button' onClick={handleSubmit} id='generator-button'>
          <FontAwesomeIcon icon={buttonIcon} className='spaced-fa-icon' />
          <p>{ tg('home.prng.generate') }</p>
        </button>
        {
          // The verify button is hidden until the user clicks on the generate button
          values.output !== undefined &&
            <button id='verify-button' className='button is-link is-light app-button' onClick={showModal}>
              <FontAwesomeIcon icon={faQrcode} className='spaced-fa-icon' />
              <span>{ t('fast-verification.short-title').toUpperCase() }</span>
            </button>
        }
      </div>

      <SharingModal qrCode={qrCode} fileName={`QR_prng_v${values.code}`} url={url}/>

    </div>
  )
}

export default RandomNumberApp