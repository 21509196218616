import React from 'react';
import PropTypes from 'prop-types';

import BasicPageWrapper from './BasicPageWrapper';
import MainBreadcrumb from './MainBreadcrumb';

export default function BreadcrumbsPageWrapper({t, children, customClass, breadcrumbs}) {
  return (
    <BasicPageWrapper t={t} customClass={customClass}>
      <div className="box-content">
        <MainBreadcrumb breadCrumbsList={breadcrumbs} />
        {children}
      </div>
    </BasicPageWrapper>
  )
}

BreadcrumbsPageWrapper.propTypes = {
  t: PropTypes.func,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  breadcrumbs: PropTypes.array.isRequired
}
