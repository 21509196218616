import React from 'react';

import { faCircleQuestion, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadFile } from '../../../utils/utils';

const FileOutput = ({t, tg, values}) => {

  return (
    <div className='has-text-centered'>
      <label>
        <p>{ values.outputList.length } { t('apps.shuffle.file-rows') }</p>
      </label>
      <div className='shuffled-container file-output' id='shuffled-result' onClick={() => downloadFile(values.outputFile)}>
        <FontAwesomeIcon icon={faDownload} className='spaced-fa-icon' />
        { t('apps.shuffle.download') }
      </div>
      <div className='code-container-light' id='code-container'>
        <span id='verification-code' className='verification-code'>
          { tg('home.prng.code-msg') }: <b>{ values.code }</b>
        </span>
        <div className='result-tooltip'>
          <FontAwesomeIcon icon={faCircleQuestion} />
          <span className='tooltiptext'>{ tg('home.prng.tooltip') }</span>
        </div>
      </div>
    </div>
  )
}

export default FileOutput