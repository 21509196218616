import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faDiceD20, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BeaconContext } from '../../Contexts';
import SharingModal from '../SharingModal';
import { qrCode } from '../../../utils/verification';
import { BINGOAPP, buildUrl, showModal } from '../../../utils/utils';
import { getUniverse } from '../../../utils/randomness';
import loadingGif from '../../../assets/images/loading.gif';

const prettify = (n) => {
  if (n<=15) return `B-${n}`;
  else if (n<=30) return `I-${n}`;
  else if (n<=45) return `N-${n}`;
  else if (n<=60) return `G-${n}`;
  else if (n<=75) return `O-${n}`;
  else return 'ERR';
}

const createUrl = (elements, pulses) => {
  let repetitions = {};
  for(const pulse of pulses) {
    if (repetitions[pulse]) {
      repetitions[pulse]++;
    } else {
      repetitions[pulse] = 1;
    }
  }
  let shortPulses = [];
  for(const [key, value] of Object.entries(repetitions)) {
    shortPulses.push(`${key}-${value}`);
  }
  const url = buildUrl(BINGOAPP, {output: elements, codes: shortPulses});
  return url;
}


const CircledNumber = ({content, active}) => {
  return (
    <>
      {
        active ? 
          <div className='circled-number is-active'>
            <label>{prettify(content)}</label>
          </div>
          :
          <div className='circled-number'>
            <label>{prettify(content)}</label>
          </div>
      }
    </>
  )
}

const BingoByCharacter = ({elements, character}) => {
  return (
    <div className={`spell-container ${elements.length > 0 ? '' : 'is-hidden'}`}>
      <div className='spelling-bingo'>{character.toUpperCase()}</div>
      <div className='nums-container nums-by-char-container'>
        { elements.map((n, i) => (<CircledNumber key={i} content={n} active={false}/>)) }
      </div>
    </div>
  )
}


const BingoApp = () => {

  const [t] = useTranslation('apps');

  const { chacha, pulse } = useContext(BeaconContext);
  const [ universe, setUniverse ] = useState(getUniverse(1, 75));
  const [ elements, setElements] = useState([]);
  const [ pulses, setPulses] = useState([]);
  const [ current, setCurrent ] = useState(-1);
  const [ url, setUrl ] = useState('');
  const size = 300;

  const handleSubmit = (e) => {
    e.preventDefault();
    document.getElementById('generator-button').disabled = true;
    let newNumber = -1;
    let newPulseCode = `${pulse.chainIndex}-${pulse.pulseIndex}`
    if(universe.length === 1) {
      newNumber = universe[0];
      setUniverse([]);
    } else {
      newNumber = chacha.sample(universe, 1)[0];
    }
    setCurrent(newNumber);
    setTimeout(() => {
      setElements((elements) => [...elements, newNumber]);
      setPulses((pulses) => [...pulses, newPulseCode]);
      document.getElementById('generator-button').disabled = false;
    }, 500);
    setUniverse((universe) => universe.filter(n => n !== newNumber));
  }

  const verifyBingo = () => {
    const new_url = createUrl(elements, pulses);
    setUrl(new_url);
    qrCode.update({ data: new_url, width: size, height: size});
    showModal('bingo-modal');
  }

  return (
    <div className='app-container-light'>
      <h1>{ t('apps.bingo.title') }</h1>
      {
        current > 0 &&
          <div className='nums-container newest-container'>
          { 
            elements.includes(current) ?
              <CircledNumber content={current} active={true}/>
              :
              <div className='loading-container has-text-centered'>
                <img src={loadingGif} alt='loading...' />
              </div>
          }
          </div>
      }

      <div className='has-text-centered buttons-container'>
        {
          universe.length > 0 ?
            <button className='button is-link is-light app-button' onClick={handleSubmit} id='generator-button'>
              <FontAwesomeIcon icon={faDiceD20} className='spaced-fa-icon' />
              <p>{ t('apps.bingo.generate').toUpperCase() }</p>
            </button>
            :
            <button className='button is-link is-light app-button' id='generator-button' disabled>
              <FontAwesomeIcon icon={faDiceD20} className='spaced-fa-icon' />
              <p>{ t('apps.bingo.end').toUpperCase() }</p>
            </button>
        }
        {
          elements.length > 0 &&
          <button className='button is-link is-light app-button' onClick={verifyBingo}>
            <FontAwesomeIcon icon={faQrcode} className='spaced-fa-icon' />
            <p>{ t('fast-verification.short-title').toUpperCase() }</p>
          </button>
        }
        
        <SharingModal qrCode={qrCode} fileName={`QR_bingo`} url={url}/>
        
      </div>
      
      <div className='old-nums-container'>
        <BingoByCharacter elements={elements.filter((e) => e !== current && e <= 15)} character='b'/>
        <BingoByCharacter elements={elements.filter((e) => e !== current && e > 15 && e <= 30 )} character='i'/>
        <BingoByCharacter elements={elements.filter((e) => e !== current && e > 30 && e <= 45)} character='n'/>
        <BingoByCharacter elements={elements.filter((e) => e !== current && e > 45 && e <= 60)} character='g'/>
        <BingoByCharacter elements={elements.filter((e) => e !== current && e > 60 && e <= 75)} character='o'/>
      </div>
    </div>
  )
}

export default BingoApp