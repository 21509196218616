import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

const FileInput = ({labels, file, setFileData, error, errorMsg}) => {

  // Reads the file and sets the data
  const updateInputFromFile = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    // const extension = file.name.slice(file.name.lastIndexOf('.'));

    // Reading Excel files requires special handling
    // TODO: Implement this
      
    // Plaintext and CSV files can be read directly
    const reader = new FileReader();
    reader.onload = (e) => {
      setFileData(file, e.target.result);
    }
    reader.readAsText(file); 
  }

  useEffect(() => {
    const fileInput = document.querySelector('.file');
    const fileError = document.querySelector('.file-error');

    if (error) {
      fileInput.classList.remove('is-info');
      fileInput.classList.add('is-danger');
      fileError.classList.remove('is-hidden');
    } else {
      fileInput.classList.remove('is-danger');
      fileInput.classList.add('is-info');
      fileError.classList.add('is-hidden');
    }
  }, [error]);

  return (
    <div className='has-text-centered mb-5'>
      <label>
        <p>{ labels.header }</p>
      </label>
      <div className='file has-name is-boxed is-info mt-3'>
        <label className='file-label'>
          <input className='file-input' type='file' accept='.tsv, .csv, text/plain' onChange={updateInputFromFile} />
          <span className='file-cta'>
            <span className='file-icon'>
              <FontAwesomeIcon icon={faUpload} className='spaced-fa-icon' />
            </span>
            <span className='file-label'>
              <p>{ labels.fileUpload }</p>
              <p>({ labels.admittedFormats }: <b>.txt</b>, <b>.csv</b>, <b>.tsv</b>)</p>
            </span>
          </span>
          <span className='file-name'>
            { file ? file.name : labels.noFile }
          </span>
        </label>
      </div>
      <p className='file-error help is-danger is-hidden'>
        { errorMsg }
      </p>
    </div>
  )
}

export default FileInput