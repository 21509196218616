import React from 'react';

import Quote from './Quote';
import RandomNumberApp from './RandomNumberApp';
import PulseCountdown from '../../components/PulseCountdown';

const Home = () => {
  return (
    <div className='body-center container'>
      <div className='columns'>
        <div className='column is-three-fifths has-text-centered'> 
          <Quote/>
        </div>
        <div className='column'>
          <div>
            <RandomNumberApp colorTheme='default' route='index' />
            <PulseCountdown /> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;
