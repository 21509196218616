import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReadme } from '@fortawesome/free-brands-svg-icons';

const BeaconBreadcrumb = ({t}) => {

  return (
    <Link to="/randomness-beacon">
      <span className="icon is-small">
        <FontAwesomeIcon icon={faReadme} />  
      </span>
      <span>{t('main-title')}</span>
    </Link>
  )
}

export default BeaconBreadcrumb